import api from './api'
import utils from './utils'

const postAdminFetchShop = (page,company,status,token) => {
  if (!status || status.length == 0) { status = null }
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchShopByPage, {
      page,
      company,
      status
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminInsertShop = (form,token) => {
  // if (form.comment.length == 0 ) { form.comment = null }
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminInsertShop, {
      username: form.username,
      password: form.password,
      company: form.company,
      name: form.name,
      phone: form.phone,
      address: form.address,
      email: form.email,
      rent: form.rent,
      paymentDate: form.paymentDate,
      comment: form.comment,
      logo: form.logo,
      secret_key: form.secret_key,
      fb_status: form.fb_status,
      fb_app_id: form.fb_app_id,
      fb_app_secret: form.fb_app_secret,
      fb_access_token: form.fb_access_token,
      fb_page_id: form.fb_page_id,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateShop = (id,form,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateShop, {
      id,
      company: form.company,
      name: form.name,
      phone: form.phone,
      address: form.address,
      email: form.email,
      rent: form.rent,
      paymentDate: form.paymentDate,
      comment: form.comment,
      logo: form.logo,
      secret_key: form.secret_key,
      fb_status: form.fb_status,
      fb_app_id: form.fb_app_id,
      fb_app_secret: form.fb_app_secret,
      fb_access_token: form.fb_access_token,
      fb_page_id: form.fb_page_id,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminUpdateStatusShop = (id,status,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateShop, {
      id,
      status
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminSetPassword = (id, password, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminSetPassword, {
      id,
      password: password,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminFetchOperatorByPage = (page, name,status,token) => {
  if (!status || status.length == 0) { status = null }
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchOperatorByPage, {
      page,
      name,
      status,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminInsertOperator = (form,token) => {
  // if (form.comment.length == 0 ) { form.comment = null }
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminInsertOperator, {
      username: form.username,
      password: form.password,
      company: form.company,
      name: form.name,
      phone: form.phone,
      address: form.address,
      email: form.email,
      rent: form.rent,
      paymentDate: form.paymentDate,
      comment: form.comment,
      logo: form.logo,
      secret_key: form.secret_key,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateOperator = (id,form,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateOperator, {
      id,
      company: form.company,
      name: form.name,
      phone: form.phone,
      address: form.address,
      email: form.email,
      rent: form.rent,
      paymentDate: form.paymentDate,
      comment: form.comment,
      logo: form.logo,
      secret_key: form.secret_key,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateStatusOperator = (id,status,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateOperator, {
      id,
      status
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export default {
  postAdminFetchShop,
  postAdminUpdateShop,
  postAdminInsertShop,
  postAdminUpdateStatusShop,
  postAdminSetPassword,
  postAdminFetchOperatorByPage,
  postAdminInsertOperator,
  postAdminUpdateOperator,
  postAdminUpdateStatusOperator,
}
