var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('search-tool',{attrs:{"input-text":"请输入职员名称","add-btn-text":"新增职员"},on:{"add":_vm.add,"onSearch":_vm.onSearch}}),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":false,"loading":_vm.boolLoading,"bordered":""},scopedSlots:_vm._u([_vm._l(([
        'name',
        'nationality',
        'nationality_zh',
        'nationalityImg',
        'age',
        'imgUrls',
        'description',
        'description_zh',
        'operation' ]),function(col){return {key:col,fn:function(text, record){return [_c('div',{key:col},[(record.editable)?_c('a-input',{staticStyle:{"margin":"-5px 0"},attrs:{"value":text},on:{"change":function (e) { return _vm.handleChange(e.target.value, record.key, col); }}}):[_vm._v(" "+_vm._s(text)+" ")]],2)]}}}),{key:"nationalityImg",fn:function(text){return [_c('div',{staticClass:"editable-row-operations"},[_c('div',{staticStyle:{"font-size":"xxx-large","line-height":"0.5"}},[_vm._v(" "+_vm._s(text)+" ")])])]}},{key:"imgUrls",fn:function(text){return [_c('div',{staticClass:"editable-row-operations"},[_c('div',_vm._l((text),function(item,index){return _c('image-tool',{key:index,staticClass:"img-list",attrs:{"img-url":item.response.data}})}),1)])]}},{key:"status",fn:function(text){return [_c('div',{staticClass:"editable-row-operations"},[(text == 'on')?_c('a-tag',{attrs:{"color":"blue"}},[_vm._v(" 正常 ")]):_c('a-tag',{attrs:{"color":"red"}},[_vm._v(" 关闭 ")])],1)]}},{key:"operation",fn:function(text, record){return [_c('div',{staticClass:"editable-row-operations"},[_c('span',[_c('a',{attrs:{"disabled":_vm.editingKey !== ''},on:{"click":function($event){return _vm.onChangeImg(record)}}},[_vm._v("编辑")])]),_c('span',[_c('a',{attrs:{"disabled":_vm.editingKey !== ''},on:{"click":function () { return _vm.status(record); }}},[_vm._v(_vm._s(record.status == "on" ? "关闭" : "恢复"))])])])]}}],null,true)}),_c('a-pagination',{staticClass:"pagination",attrs:{"show-quick-jumper":"","default-current":1,"page-size":20,"total":_vm.total},on:{"change":_vm.onFetchStaff},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('staff-form',{ref:"staffForm",on:{"handleOk":_vm.onAddStaff,"handleEditOk":_vm.onUpdateStaff}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }