<template>
  <div>
    <div class="full-block">
      <a-spin :spinning="boolLoading">
        <a-tabs
          :v-model="tabIndex"
          class="main-tab"
        >
          <div
            v-if="currentUser.permission === 'normal'"
            slot="tabBarExtraContent"
          >
            <a-button
              type="link"
              @click="showHistory"
            >
              上工历史记录
            </a-button>
          </div>
          <a-tab-pane
            v-for="(item, index) in recordList"
            :key="index"
          >
            <div
              slot="tab"
              :class="{ alert: item.isAlert }"
              style="padding-left: 10px; padding-right: 10px"
              @click="onTabChange(index)"
            >
              <div class="text">
                {{ item.staffName }}
              </div>
              <div class="text">
                {{ item.room ? item.room.title : null }}
              </div>
              <div class="text">
                {{ item.residueTime }}
              </div>
            </div>
            <record-form
              :ref="'form' + index"
              :record="item"
              :selected-index="tabIndex"
              :record-list="recordList"
              @onFetchRecord="onFetchRecord"
            />
          </a-tab-pane>
          <a-tab-pane :key="-1">
            <span
              slot="tab"
              @click="onTabChange(-1)"
            >
              <div class="text">
                <a-icon type="plus" />
                新增记录
              </div>
            </span>
            <record-form
              :record-list="recordList"
              :selected-index="tabIndex"
              @onFetchRecord="onFetchRecord"
            />
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
  </div>
</template>

<script>
import recordForm from '../components/record/recordForm.vue';
import RecordServices from '../services/RecordServices';

function today() {
  return (
    new Date().getFullYear() +
    '-' +
    (new Date().getMonth() + 1) +
    '-' +
    new Date().getDate() +
    ' ' +
    new Date().getHours() +
    ':' +
    new Date().getMinutes() +
    ':' +
    new Date().getSeconds()
  );
}

export default {
  components: {
    recordForm,
  },
  data() {
    return {
      recordList: [],
      tabIndex: -1,
      timeLists: [],
      boolLoading: false,
      timeId: null,
    };
  },
  computed: {
    currentUser: function () {
      return JSON.parse(localStorage.currentUser);
    }
  },
  created() {
    // 实例被创建之后执行代码
    this.onFetchRecord();
  },
  beforeDestroy() {
    //组件的销毁
    clearInterval(this.timeId); // 清除定时器
  },
  methods: {
    showHistory() {
      this.$router.push('/RecordList');
      this.$store.state.menuKeys = ['RecordList'];
    },
    onFetchRecord() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminFetchRecordByDate(token, today());
      request
        .then((res) => {
          that.recordList = res.data.data.map((row) => ({
            key: row.id,
            residueTime: '',
            ...row,
          }));
          that.setIntervalTime();
        })
        .catch(() => {})
        .finally(() => {
          setTimeout(function () {
            that.boolLoading = false;
          }, 1000);
        });
    },
    onTabChange(index) {
      this.tabIndex = index;
      this.onFetchRecord();
    },
    // 设置定时器做倒计时
    setIntervalTime() {
      clearInterval(this.timeId);
      this.timeId = setInterval(() => {
        this.recordList.forEach((item) => {
          item.residueTime = this.getResidueTime(item.startTime, item.endTime).text;
          item.isAlert = this.getResidueTime(item.startTime, item.endTime).seconds < (60 * 5);
        });
      }, 1000);
    },
    // 获取剩余时间
    getResidueTime(start, end) {
      let nowtime = new Date().getTime(); // 当前时间 毫秒数
      let startTime = Date.parse(new Date(start.replace(/-/g, '/'))); //结束时间  毫秒数
      let endTime = Date.parse(new Date(end.replace(/-/g, '/'))); //结束时间  毫秒数

      let totalSeconds = (endTime - nowtime) / 1000; // 结束时间-当前时间 = 剩余多少时间
      let hour = parseInt((totalSeconds / 3600) % 24); //时
      let minute = parseInt((totalSeconds / 60) % 60); //分
      let second = parseInt(totalSeconds % 60); //秒
      let residueTime = hour + '时 ' + minute + '分 ' + second + '秒';
      hour = this.addZero(hour);
      minute = this.addZero(minute);
      second = this.addZero(second);
      if (startTime > nowtime) {
        residueTime = '开始于：' + start;
        return residueTime;
      }
      if (totalSeconds < 0) {
        // 记录到时提示
        residueTime = '时间到';
      }
      return {
        text: residueTime,
        seconds: totalSeconds,
      };
    },
    // 补齐格式不满10加0
    addZero(n) {
      return n < 10 ? '0' + n : n;
    },
  },
};
</script>

<style scoped>
.full-block {
  width: 100%;
  height: 100%;
}

.main-tab >>> .alert {
  background-color: red;
  color: white;
  border-radius: 3px;
}

</style>
