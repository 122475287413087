import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.less';
import router from './router';
import md5 from 'js-md5';
import VueCookies from 'vue-cookies';
import JsonExcel from 'vue-json-excel';
import VueClipboard from 'vue-clipboard2';
import Vuex from 'vuex';

Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;

Vue.component('downloadExcel', JsonExcel);

Vue.use(Antd);
Vue.use(VueClipboard);
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    menuKeys: ['Staff'],
  },
  mutations: {
    increment (state, menuKeys) {
      state.menuKeys = menuKeys;
    }
  }
});

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app');
