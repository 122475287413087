import api from './api'
import utils from './utils'

const postAdminFetchArrange = (startTime, endTime, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchArrange, {
      startTime,
      endTime,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const postAdminFetchArrangeById = (id, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchArrangeById, {
      id,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminInsertArrange = (form, token) => {
  var data = {
    belongStaffId: form.belongStaffId,
    startTime: new Date(form.startTime).getFullYear() + "-" + (new Date(form.startTime).getMonth() + 1) +
      "-" + new Date(form.startTime).getDate() + " " + new Date(form.startTime).getHours() +
      ":" + new Date(form.startTime).getMinutes() + ":" + new Date(form.startTime).getSeconds(),

    allDay: form.allDay,
  }
  if (form.endTime) {
    data.endTime = new Date(form.endTime).getFullYear() + "-" + (new Date(form.endTime).getMonth() + 1) +
      "-" + new Date(form.endTime).getDate() + " " + new Date(form.endTime).getHours() +
      ":" + new Date(form.endTime).getMinutes() + ":" + new Date(form.endTime).getSeconds()
  } else {
    data.endTime = null
  }
  // 如是整天设00:00:00
  if (form.allDay) {
    data.startTime = new Date(form.startTime).getFullYear() + "-" + (new Date(form.startTime).getMonth() + 1) +
      "-" + new Date(form.startTime).getDate() + " 00:00:00"
  }

  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminInsertArrange, data, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminUpdateArrange = (id, form, token) => {
  var data = {
    id: id,
    belongStaffId: form.belongStaffId,
    startTime: new Date(form.startTime).getFullYear() + "-" + (new Date(form.startTime).getMonth() + 1) +
      "-" + new Date(form.startTime).getDate() + " " + new Date(form.startTime).getHours() +
      ":" + new Date(form.startTime).getMinutes() + ":" + new Date(form.startTime).getSeconds(),

    allDay: form.allDay,
  }
  if (form.endTime) {
    data.endTime = new Date(form.endTime).getFullYear() + "-" + (new Date(form.endTime).getMonth() + 1) +
      "-" + new Date(form.endTime).getDate() + " " + new Date(form.endTime).getHours() +
      ":" + new Date(form.endTime).getMinutes() + ":" + new Date(form.endTime).getSeconds()
  } else {
    data.endTime = null
  }

  // 如是整天设00:00:00
  if (form.allDay) {
    data.startTime = new Date(form.startTime).getFullYear() + "-" + (new Date(form.startTime).getMonth() + 1) +
      "-" + new Date(form.startTime).getDate() + " 00:00:00"
  }

  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateArrange, data, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const postAdminUpdateStatusArrange = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateArrange, {
      id,
      status
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  postAdminFetchArrange,
  postAdminFetchArrangeById,
  postAdminInsertArrange,
  postAdminUpdateArrange,
  postAdminUpdateStatusArrange
}