<template>
  <div>
    <a-modal
      width="60%"
      title="排班操作"
      :mask-closable="false"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          label="职员"
          required
        >
          <a-select
            style="width: 200px"
            :options="staffList"
            :value="form.belongStaffId"
            @change="onChangeStaff"
          />
        </a-form-model-item>

        <a-form-model-item
          label="整天上班"
          required
        >
          <a-switch
            :checked="form.allDay"
            @change="onAllDayChange"
          />
        </a-form-model-item>

        <a-form-model-item
          label="开始时间"
          required
        >
          <a-date-picker
            :format="form.allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'"
            :show-time="!form.allDay"
            :value="form.startTime"
            @change="onStartChange"
          />
        </a-form-model-item>

        <a-form-model-item
          v-if="!form.allDay"
          label="结束时间"
        >
          <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            :show-time="true"
            :value="form.endTime"
            @change="onEndChange"
          />
        </a-form-model-item>

        <a-form-model-item
          v-if="boolEdit"
          label="删除排班"
        >
          <a-button
            type="danger"
            @click="onStatus"
          >
            确认删除
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import StaffServices from '../../services/StaffServices';
import ArrangeServices from '../../services/ArrangeServices';
import moment from 'moment';

export default {
  props: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        title: '',
        belongStaffId: '',
        startTime: '',
        endTime: '',
        allDay: true,
      },
      loading: false,
      visible: false,
      boolEdit: false,
      staffList: [],
      formSearch: {
        input: '',
      },
    };
  },
  created() {
    this.onFetchStaff();
  },
  methods: {
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.boolEdit) {
            this.$emit('handleEditOk', this.form);
          } else {
            this.$emit('handleOk', this.form);
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = !this.visible;
      this.boolEdit = false;
      this.onReset();
    },
    onReset(event) {
      this.form = {
        title: '',
        belongStaffId: '',
        startTime: null,
        endTime: null,
        allDay: true,
      };
      if (event != undefined) {
        this.form.startTime = event;
      }
    },
    onAllDayChange() {
      this.form.allDay = !this.form.allDay;
    },

    onFetchStaff() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = StaffServices.postAdminFetchAllStaff(
        that.formSearch.input,
        token
      );
      request
        .then((res) => {
          that.staffList = res.data.data.map((row) => ({
            key: row.id,
            title: row.name,
            value: row.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onChangeStaff(e) {
      this.form.belongStaffId = e;
    },

    onStartChange(e) {
      console.log(e);
      this.form.startTime = e;
    },

    onEndChange(e) {
      console.log(e);
      this.form.endTime = e;
    },

    onPassForm(id) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ArrangeServices.postAdminFetchArrangeById(id, token);
      request
        .then((res) => {
          res.data.data.startTime = moment(res.data.data.startTime);
          if (res.data.data.endTime != null) {
            res.data.data.endTime = moment(res.data.data.endTime);
          }
          that.form = res.data.data;
          console.log(that.form);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
    onStatus() {
      this.$emit('onStatus', this.form);
    },

    onDropped(date, staffId) {
      this.form = {
        belongStaffId: staffId,
        startTime: date,
        endTime: null,
        allDay: true,
      };
      this.visible = true;
    },
  },
};
</script>

<style>
</style>