<template>
  <a-modal
    width="60%"
    title="设置密码"
    :mask-closable="false"
    :visible="visible"
    :confirm-loading="loading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :rules="rules"
    >
      <a-form-model-item
        has-feedback
        prop="password"
        label="密码"
      >
        <a-input
          v-model="form.password"
          type="password"
        />
      </a-form-model-item>
      <a-form-model-item
        has-feedback
        prop="passwordRepeat"
        label="重复密码"
      >
        <a-input
          v-model="form.passwordRepeat"
          type="password"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'ShopPasswordForm',
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.passwordRepeat !== '') {
          this.$refs.form.validateField('passwordRepeat');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: null,
        password: null,
        passwordRepeat: null,
      },
      loading: false,
      visible: false,
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 4, message: '至少4位', trigger: 'blur' },
          { validator: validatePass, trigger: 'change' }
        ],
        passwordRepeat: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { min: 4, message: '至少4位', trigger: 'blur' },
          { validator: validatePass2, trigger: 'change' }
        ],
        resource: [
          { required: true, message: 'Please select activity resource', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    init(record) {
      this.visible = true;
      this.form = {
        id: record.id,
        password: null,
        passwordRepeat: null,
      };
    },
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('handleOk', this.form);
        } else {
          console.log('invalid');
        }
        this.visible = false;
      });
    },
    handleCancel() {
      this.visible = !this.visible;
    },
  }
};
</script>

<style scoped>

</style>