<template>
  <div>
    <a-modal
      width="60%"
      title="服务操作"
      :maskClosable="false"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="名称" required>
          <a-input v-model="form.name" />
        </a-form-model-item>
        <a-form-model-item label="时长" required>
          <a-input class="time-1" v-model="form.duration" type="number" />
          <div class="time-2">分钟</div>
        </a-form-model-item>
        <a-form-model-item label="工资" required>
          <a-input v-model="form.salary" type="number" />
        </a-form-model-item>
        <a-form-model-item label="利润" required>
          <a-input v-model="form.profit" type="number" />
        </a-form-model-item>
        <a-form-model-item label="价格" required>
          <a-input v-model="form.price" type="number" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        duration: "",
        salary: "",
        profit: "",
        price: "",
      },
      loading: false,
      visible: false,
    };
  },
  methods: {
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("handleOk", this.form);
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = !this.visible;
      this.onReset();
    },
    onReset() {
      this.form = {
        name: "",
        duration: "",
        salary: "",
        profit: "",
        price: "",
      };
    },
  },
};
</script>

<style>
.time-1{
  width: 70%;
  display: inline-block;
}
.time-2{
  width: 20%;
  display: inline-block;
  margin-left: 10px;
}

</style>