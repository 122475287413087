<template>
  <div>
    <search-tool
      input-text="请输入职员名称"
      add-btn-text="新增职员"
      @add="add"
      @onSearch="onSearch"
    />
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="boolLoading"
      bordered
    >
      <template
        v-for="col in [
          'name',
          'nationality',
          'nationality_zh',
          'nationalityImg',
          'age',
          'imgUrls',
          'description',
          'description_zh',
          'operation',
        ]"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record.key, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template
        slot="nationalityImg"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <div style="font-size: xxx-large; line-height: 0.5">
            {{ text }}
          </div>
        </div>
      </template>
      <template
        slot="imgUrls"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <div>
            <image-tool
              v-for="(item, index) in text"
              :key="index"
              class="img-list"
              :img-url="item.response.data"
            />
          </div>
        </div>
      </template>
      <template
        slot="status"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <a-tag
            v-if="text == 'on'"
            color="blue"
          >
            正常
          </a-tag>
          <a-tag
            v-else
            color="red"
          >
            关闭
          </a-tag>
        </div>
      </template>
      <template
        slot="operation"
        slot-scope="text, record"
      >
        <div class="editable-row-operations">
          <!-- <span v-if="record.editable">
            <a @click="() => save(record.key)">保存</a>
            <a-popconfirm
              title="Sure to cancel?"
              @confirm="() => cancel(record.key)"
            >
              <a>取消</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a :disabled="editingKey !== ''" @click="() => edit(record.key)"
              >编辑</a
            >
          </span> -->
          <span>
            <a
              :disabled="editingKey !== ''"
              @click="onChangeImg(record)"
            >编辑</a>
          </span>
          <span>
            <a
              :disabled="editingKey !== ''"
              @click="() => status(record)"
            >{{
              record.status == "on" ? "关闭" : "恢复"
            }}</a>
          </span>
        </div>
      </template>
    </a-table>
    <a-pagination
      v-model="page"
      class="pagination"
      show-quick-jumper
      :default-current="1"
      :page-size="20"
      :total="total"
      @change="onFetchStaff"
    />
    <staff-form
      ref="staffForm"
      @handleOk="onAddStaff"
      @handleEditOk="onUpdateStaff"
    />
  </div>
</template>
<script>
const columns = [
  {
    title: '姓名',
    width: '5%',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '国旗',
    width: '5%',
    dataIndex: 'nationalityImg',
    scopedSlots: { customRender: 'nationalityImg' },
  },
  {
    title: '国籍EN',
    width: '5%',
    dataIndex: 'nationality',
    scopedSlots: { customRender: 'nationality' },
  },
  {
    title: '国籍ZH',
    width: '5%',
    dataIndex: 'nationality_zh',
    scopedSlots: { customRender: 'nationality_zh' },
  },
  {
    title: '年龄',
    width: '5%',
    dataIndex: 'age',
    scopedSlots: { customRender: 'age' },
  },
  {
    title: '照片',
    width: '20%',
    dataIndex: 'imgUrls',
    scopedSlots: { customRender: 'imgUrls' },
  },
  {
    title: '描述EN',
    dataIndex: 'description',
    width: '20%',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '描述ZH',
    dataIndex: 'description_zh',
    width: '20%',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '状态',
    width: '5%',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '15%',
    scopedSlots: { customRender: 'operation' },
  },
];

import StaffServices from '../services/StaffServices';
import searchTool from '../components/searchTool.vue';
import staffForm from '../components/staff/staffForm.vue';
import imageTool from '../components/imageTool.vue';

export default {
  components: {
    searchTool,
    staffForm,
    imageTool,
  },
  data() {
    return {
      total: 0,
      page: 1,
      data: [],
      cacheData: [],
      columns,
      editingKey: '',
      boolLoading: false,
      formSearch: {
        input: '',
      },
      windowHeight: window.innerHeight,

    };
  },
  created() {
    this.onFetchStaff();
  },
  methods: {
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      this.onUpdateStaff(target);
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = '';
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    add() {
      this.$refs.staffForm.onReset();
      this.$refs.staffForm.visible = !this.$refs.staffForm.visible;
    },
    onSearch(formPass) {
      this.formSearch = formPass;
      this.page = 1;
      this.onFetchStaff();
    },
    onFetchStaff() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = StaffServices.postAdminFetchStaff(
        that.page,
        that.formSearch.input,
        token
      );
      request
        .then((res) => {
          that.total = res.data.data.total;
          that.data = res.data.data.data.map((row) => ({
            key: row.id,
            ...row,
          }));
          that.cacheData = that.data.map((item) => ({ ...item }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onAddStaff(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = StaffServices.postAdminInsertStaff(form, token);
      request
        .then((res) => {
          console.log(res);
          if (res.data.code == 500) {
            that.$message.error('新增失败');
            return;
          }
          that.onFetchStaff();
          that.add();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onUpdateStaff(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = StaffServices.postAdminUpdateStaff(form.id, form, token);
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          }
          that.onFetchStaff();
          that.add();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onChangeImg(record) {
      this.$refs.staffForm.onSetStaff(record);
      this.$refs.staffForm.boolEdit = true;
      this.$refs.staffForm.visible = !this.$refs.staffForm.visible;
    },

    status(record) {
      let that = this;
      var status = 'on';
      if (record.status == 'on') {
        status = 'off';
      }
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = StaffServices.postAdminUpdateStatusStaff(
        record.key,
        status,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          }
          that.onFetchStaff();
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchStaff();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.pagination {
  margin-top: 20px;
}
.row-img {
  width: 100px;
  height: 100px;
}
.img-list {
  display: inline-block;
  margin-right: 20px;
}
.img-single {
  width: 20px;
  height: 20px;
}

</style>
