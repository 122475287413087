<template>
  <div>
    <div class="container-1">
      <a-tabs
        class="staff-tab"
        :active-key="form.belongStaffId"
        tab-position="left"
        :style="{ height: (windowHeight - 170) + 'px' }"
        @change="onChangeStaff"
      >
        <a-tab-pane
          v-for="item in staffList"
          :key="item.id"
        >
          <span slot="tab">
            <div class="text">
              <div
                v-if="item.isWorking"
                class="remind"
              >(上工中)</div>
              {{ item.name }} {{ item.nationalityImg }}
            </div>
          </span>
        </a-tab-pane>
      </a-tabs>
    </div>

    <div class="container-2">
      <div class="box">
        <div class="block-2">
          <a-button
            v-if="Object.keys(this.record).length == 0"
            type="primary"
            :loading="boolLoading"
            @click="onAdd"
          >
            新增记录
          </a-button>
          <a-button
            v-else
            type="primary"
            :loading="boolLoading"
            @click="onEdit"
          >
            修改记录
          </a-button>
        </div>
        <div style="padding-top: 10px" />

        <div class="block">
          <div class="block-title">
            <div class="required-sign">
              *
            </div>
            服务类型：
          </div>
          <a-select
            class="block-content"
            :options="typeList"
            :value="form.belongTypeId"
            @change="onChangeType"
          />
        </div>

        <div class="block">
          <div class="block-title">
            <div class="required-sign">
              *
            </div>
            支付方式：
          </div>
          <a-select
            class="block-content"
            :options="paymentList"
            :value="form.paymentWay"
            @change="onChangePayment"
          />
        </div>
        <div style="padding-top: 10px" />
        <div class="block">
          <div class="block-title">
            <div class="required-sign">
              *
            </div>
            开始时间：
          </div>
          <a-space>
            <a-date-picker
              v-model="form.startTime"
              format="YYYY-MM-DD"
              :disabled="Object.keys(this.record).length > 0"
              style="width: 150px"
              @change="onChangeStart"
            />
            <a-time-picker
              v-model="form.startTime"
              :minute-step="10"
              :disabled="Object.keys(this.record).length > 0"
              use12-hours
              format="h:mm a"
              style="width: 150px"
            />
            <a-button
              v-if="Object.keys(this.record).length == 0"
              type="link"
              @click="resetStartTime"
            >
              设为此刻
            </a-button>
          </a-space>
        </div>
        <div class="block">
          <div class="block-title">
            时长：
          </div>
          <a-input
            v-model="form.duration"
            disabled
            class="block-content"
            type="number"
          />
        </div>

        <div style="padding-top: 10px" />

        <div class="block">
          <div class="block-title">
            价格：
          </div>
          <a-input
            v-model="form.typePrice"
            class="block-content"
            type="number"
            disabled
          />
        </div>
        <div class="block">
          <div class="block-title">
            工资：
          </div>
          <a-input
            v-model="form.typeSalary"
            class="block-content"
            type="number"
            disabled
          />
        </div>
        <div style="padding-top: 10px" />

        <div class="block">
          <div class="block-title">
            房间
          </div>
          <a-select
            v-model="form.room_id"
            class="block-content"
            :options="roomList"
          />
        </div>
        
        <div class="block">
          <div class="block-title">
            备注：
          </div>
          <a-input
            v-model="form.comment"
            class="block-content"
          />
        </div>
        <div style="padding-top: 10px" />

        <div class="block">
          <div class="block-title">
            <div class="required-sign">
              *
            </div>
            总价：
          </div>
          <a-input-number
            v-model="form.price"
            class="block-content"
            :min="0"
            :step="0.01"
          />
        </div>
        <div class="block">
          <div class="block-title">
            <div class="required-sign">
              *
            </div>
            总工资：
          </div>
          <a-input-number
            v-model="form.salary"
            class="block-content"
            :min="0"
            :step="0.01"
          />
        </div>
      </div>

      <div class="box">
        <div>
          <div class="container">
            <a-button @click="onAddServices">
              新增额外服务
            </a-button>
          </div>
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="false"
            bordered
          >
            <template
              v-for="col in ['price', 'salary', 'comment']"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="(e) => handleChange(e.target.value, record.key, col)"
                />
                <template v-else>
                  {{ text }}
                </template>
              </div>
            </template>
            <template
              slot="operation"
              slot-scope="text, record"
            >
              <div class="editable-row-operations">
                <!-- <span v-if="record.editable">
                  <a @click="() => save(record.key)">保存</a>
                  <a-popconfirm
                    title="Sure to cancel?"
                    @confirm="() => cancel(record.key)"
                  >
                    <a>取消</a>
                  </a-popconfirm>
                </span>
                <span v-else>
                  <a
                    :disabled="editingKey !== ''"
                    @click="() => edit(record.key)"
                    >编辑</a
                  >
                </span> -->
                <span style="margin-left: 8px">
                  <a
                    :disabled="editingKey !== ''"
                    @click="() => onEditServices(record)"
                  >编辑</a>
                </span>
                <span style="margin-left: 8px">
                  <a
                    :disabled="editingKey !== ''"
                    @click="() => onDeleteServices(record.key)"
                  >删除</a>
                </span>
              </div>
            </template>
          </a-table>
        </div>
        <a-modal
          width="60%"
          title="新增额外服务"
          :mask-closable="false"
          :visible="visible"
          :confirm-loading="boolLoading"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <a-form-model
            ref="formServices"
            :model="formServices"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="支付方式">
              <a-select
                v-model="formServices.paymentWay"
                :options="paymentList"
              />
            </a-form-model-item>
            <a-form-model-item label="价格">
              <a-input
                v-model="formServices.price"
                type="number"
              />
            </a-form-model-item>
            <a-form-model-item label="工资">
              <a-input
                v-model="formServices.salary"
                type="number"
              />
            </a-form-model-item>
            <a-form-model-item label="备注">
              <a-input v-model="formServices.comment" />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import RecordServices from '../../services/RecordServices';
import TypeServices from '../../services/TypeServices';
import moment from 'moment';

function today() {
  return (
    new Date().getFullYear() +
    '-' +
    (new Date().getMonth() + 1) +
    '-' +
    new Date().getDate() +
    ' ' +
    new Date().getHours() +
    ':' +
    new Date().getMinutes() +
    ':' +
    new Date().getSeconds()
  );
}
export default {
  props: {
    record: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedIndex: {
      type: Number,
      default: -1,
    },
    recordList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      boolLoading: false,

      staffList: [],

      typeList: [],
      roomList: [],

      paymentList: [
        {
          key: 'cash',
          value: 'cash',
          title: '现金',
        },
        {
          key: 'card',
          value: 'card',
          title: '银行卡',
        },
        {
          key: 'other',
          value: 'other',
          title: '其他',
        },
      ],

      form: {
        belongStaffId: '',
        belongTypeId: '',
        price: '',
        salary: '',
        profit: '',
        typeName: '',
        typePrice: '',
        typeProfit: '',
        typeSalary: '',
        duration: '',
        comment: '',
        startTime: null,
        paymentWay: '',
        extraServices: [],
        room_id: null,
      },

      columns: [
        {
          title: '支付方式',
          dataIndex: 'paymentWay',
          scopedSlots: { customRender: 'paymentWay' },
        },
        {
          title: '价格',
          dataIndex: 'price',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: '工资',
          dataIndex: 'salary',
          scopedSlots: { customRender: 'salary' },
        },
        {
          title: '备注',
          dataIndex: 'comment',
          scopedSlots: { customRender: 'comment' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      editingKey: '',
      data: [],
      cacheData: [],

      formServices: {
        paymentWay: 'cash',
        price: '',
        salary: '',
        profit: '',
        comment: '',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      visible: false,
    };
  },
  watch: {
    recordList: {
      handler() {
        this.onCheckIfStaffWorking();
      },
      deep: true,
    },
  },
  created() {
    this.onFetchStaff();
    this.onFetchType();
  },
  mounted() {
    this.onSetForm(this.record, true);
  },
  methods: {
    onFetchStaff() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminFetchStaff(token, today());
      request
        .then((res) => {
          var list = [];
          res.data.data.forEach((item) => {
            item.forEach((element) => {
              const found = list.find(i => {
                return i.belongStaffId === element.belongStaffId;
              });
              if (!found) {
                list.push(element);
              }
            });
          });
          that.staffList = list;
          that.onFetchRecord();
        })
        .catch(() => {})
        .finally(() => {
          that.boolLoading = false;
        });
    },
    onFetchType() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = TypeServices.postAdminFetchAllType(token);
      request
        .then((res) => {
          that.typeList = res.data.data.map((row) => ({
            key: row.id,
            data: row,
            value: row.id,
            title: row.name,
          }));
        })
        .catch(() => {})
        .finally(() => {
          that.boolLoading = false;
        });

      const request1 = TypeServices.postAdminFetchAllRoom(token);
      request1
        .then((res) => {
          that.roomList = res.data.data.map((row) => ({
            key: row.id,
            data: row,
            value: row.id,
            title: row.title,
          }));
        })
        .catch(() => {})
        .finally(() => {
          that.boolLoading = false;
        });
    },
    onChangeType(e, item) {
      this.form.belongTypeId = e;
      this.form.typeName = item.data.props.data.name;
      this.form.typePrice = item.data.props.data.price;
      this.form.typeProfit = item.data.props.data.profit;
      this.form.typeSalary = item.data.props.data.salary;
      this.form.duration = item.data.props.data.duration;
      // 计算总价
      this.onCalTotal();
    },
    onChangeStaff(e) {
      if (Object.keys(this.record).length > 0) {
        return;
      }
      this.form.belongStaffId = e;
    },
    onChangeStart(e) {
      this.form.startTime = e;
    },
    onChangePayment(e) {
      this.form.paymentWay = e;
    },

    onCalTotal() {
      var totalPrice = 0;
      var totalSalary = 0;
      var totalProfit = 0;
      // 服务价
      totalPrice += parseFloat(this.form.typePrice);
      totalSalary += parseFloat(this.form.typeSalary);
      totalProfit += parseFloat(this.form.typeProfit);

      // 额外服务
      this.form.extraServices.forEach((item) => {
        totalPrice += parseFloat(item.price);
        totalSalary += parseFloat(item.salary);
        totalProfit += parseFloat(item.profit);
      });

      this.form.price = totalPrice;
      this.form.salary = totalSalary;
      this.form.profit = totalProfit;
    },

    onAdd() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      const request = RecordServices.postAdminInsertRecord(that.form, token);
      request
        .then((res) => {
          if (res.data.code != 200) {
            that.$message.error('新增失败，尚有未填信息');
            return;
          }
          that.$emit('onFetchRecord');
          that.onResetForm();
        })
        .catch(() => {
          that.$message.error('新增失败，尚有未填信息');
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
    onEdit() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      const request = RecordServices.postAdminUpdateRecord(that.form, token);
      request
        .then((res) => {
          if (res.data.code != 200) {
            that.$message.error('修改失败，尚有未填信息');
            return;
          } else {
            that.$emit('onFetchRecord');
            that.$message.success('修改成功');
          }
          // that.onResetForm();
        })
        .catch(() => {
          that.$message.error('修改失败');
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onLoading(bool) {
      this.boolLoading = bool;
    },

    onSetForm(form, boolFirst = false) {
      if (Object.keys(form).length == 0) {
        this.onResetForm();
        return;
      }
      var startTime = form.startTime;
      startTime = moment(form.startTime);
      this.form = {
        id: form.id,
        belongStaffId: form.belongStaffId,
        belongTypeId: form.belongTypeId,
        price: form.price,
        salary: form.salary,
        profit: form.profit,
        typeName: form.typeName,
        typePrice: form.typePrice,
        typeProfit: form.typeProfit,
        typeSalary: form.typeSalary,
        duration: form.duration,
        comment: form.comment,
        startTime: startTime,
        paymentWay: form.paymentWay,
        extraServices: form.extraServices,
        room_id: form.room_id,
      };
      this.data = form.extraServices.map((row, index) => ({
        key: index,
        ...row,
      }));
      this.cacheData = this.data.map((item) => ({ ...item }));
      if (!boolFirst) {
        this.onCalTotal();
      }
    },
    onResetForm() {
      this.form = {
        belongStaffId: '',
        belongTypeId: '',
        price: '',
        salary: '',
        profit: '',
        typeName: '',
        typePrice: '',
        typeProfit: '',
        typeSalary: '',
        duration: '',
        comment: '',
        startTime: moment(),
        paymentWay: 'cash',
        extraServices: [],
        room_id: null,
      };
      this.data = [];
      this.cacheData = [];
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    /*
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
      target.price;
      this.formServices.price = parseFloat(target.price);
      this.formServices.salary = parseFloat(target.salary);
      this.formServices.profit =
        this.formServices.price - this.formServices.salary;
      this.form.extraServices.forEach((item) => {
        if (item.id == target.id) {
          item.price = this.formServices.price;
          item.salary = this.formServices.salary;
          item.profit = this.formServices.profit;
        }
      });
      this.onCalTotal();
    },
    */
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = '';
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    onAddServices() {
      if (this.form.belongTypeId == '') {
        this.$message.error('请先选择服务类型');
        return;
      }
      this.visible = true;
    },
    onDeleteServices(index) {
      var list = this.form.extraServices;
      list.splice(index, 1);
      this.data = list.map((row, index) => ({
        key: index,
        ...row,
      }));
      this.cacheData = this.data.map((item) => ({ ...item }));
      this.formServices = this.data;
      this.onCalTotal();
      this.onResetServices();
    },
    handleOk() {
      if (this.formServices.price == '' || this.formServices.salary == '') {
        this.$message.error('添加额外服务失败');
        return;
      }

      this.formServices.price = parseFloat(this.formServices.price);
      this.formServices.salary = parseFloat(this.formServices.salary);

      this.formServices.profit =
        this.formServices.price - this.formServices.salary;
      if (this.boolEditServices) {
        // 编辑
        this.form.extraServices.forEach((item) => {
          if (item.id == this.formServices.id) {
            item.paymentWay = this.formServices.paymentWay;
            item.price = this.formServices.price;
            item.salary = this.formServices.salary;
            item.profit = this.formServices.profit;
            item.comment = this.formServices.comment;
          }
        });
      } else {
        // 新增
        this.formServices.id = this.form.extraServices.length;
        this.form.extraServices.push(this.formServices);
      }
      this.data = this.form.extraServices.map((row, index) => ({
        key: index,
        ...row,
      }));
      this.cacheData = this.data.map((item) => ({ ...item }));

      this.boolEditServices = false;

      this.handleCancel();
      this.onCalTotal();
    },
    handleCancel() {
      this.visible = !this.visible;
      this.boolEditServices = false;
      this.onResetServices();
    },
    onResetServices() {
      this.formServices = {
        paymentWay: 'cash',
        price: '',
        salary: '',
        profit: '',
        comment: '',
      };
    },

    onEditServices(record) {
      this.visible = true;
      this.boolEditServices = true;
      this.formServices = {
        id: record.id,
        paymentWay: record.paymentWay,
        price: record.price,
        salary: record.salary,
        profit: record.profit,
        comment: record.comment,
      };
    },

    onCheckIfStaffWorking() {
      var list = [];
      this.recordList.forEach((item) => {
        const startTime = moment(item.startTime);
        const now = moment();
        if (item.residueTime !== '时间到' && now.isAfter(startTime)) {
          list.push(item.belongStaffId);
        }
      });
      var listSec = [];
      this.staffList.forEach((item) => {
        if (list.includes(item.id)) {
          item.isWorking = true;
        } else {
          item.isWorking = false;
        }
        listSec.push(item);
      });
      this.staffList = listSec;

      if (Object.keys(this.record).length === 0) {
        this.form.startTime = moment();
      }
    },

    resetStartTime() {
      this.form.startTime = moment();
    }
  },
};
</script>

<style scoped>
.container-1 {
  width: 20%;
  display: inline-block;
  vertical-align: top;
}
.list {
  overflow: scroll;
}

.container-2 {
  width: 80%;
  display: inline-block;
  vertical-align: top;
}
.box {
  padding: 10px;
}
.block-title {
  width: 100px;
  display: inline-block;
}
.block-content {
  width: 250px;
  display: inline-block;
}
.block {
  width: 50%;
  display: inline-block;
}
.block-2 {
  width: 100%;
  text-align: right;
}
.container {
  width: 100%;
  text-align: right;
  padding-bottom: 10px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.required-sign {
  color: #f52222;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.remind {
  color: #f52222;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

.staff-tab >>> .ant-tabs-bar {
  width: 200px;
}

.staff-tab >>> .ant-tabs-tab {
  padding: 18px 10px;
  border: 1px solid #722ed1;
  background-color: initial;
  color: #722ed1;
  text-align: left;
  line-height: 0.5;
  border-radius: 3px;
  width: 190px;
}

.staff-tab >>> .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #722ed1;
  color: white;
}

</style>