<template>
  <div>
    <search-tool
      add-btn-text="新增房间"
      input-text="请输入名称"
      @add="add"
      @onSearch="onSearch"
    />
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="boolLoading"
      bordered
    >
      <template
        v-for="col in ['roomTitle', 'description',]"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record.key, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template
        slot="status"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <a-tag
            v-if="text === 'on'"
            color="blue"
          >
            正常
          </a-tag>
          <a-tag
            v-else
            color="red"
          >
            关闭
          </a-tag>
        </div>
      </template>
      <template
        slot="operation"
        slot-scope="text, record"
      >
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a @click="() => save(record.key)">保存</a>
            <a-popconfirm
              title="Sure to cancel?"
              @confirm="() => cancel(record.key)"
            >
              <a>取消</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a
              :disabled="editingKey !== ''"
              @click="() => edit(record.key)"
            >编辑</a>
          </span>
          <span>
            <a
              :disabled="editingKey !== ''"
              @click="() => status(record)"
            >{{ record.status === "on" ? "关闭" : "恢复" }}</a>
          </span>
        </div>
      </template>
    </a-table>
    <a-pagination
      v-model="page"
      class="pagination"
      show-quick-jumper
      :default-current="1"
      :page-size="20"
      :total="total"
      @change="onFetchList"
    />

    <room-form
      ref="typeForm"
      @handleOk="onAddType"
    />
  </div>
</template>
<script>
const columns = [
  {
    title: '名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'roomTitle' },
  },
  {
    title: '描述',
    dataIndex: 'description',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];

import TypeServices from '../services/TypeServices';
import searchTool from '../components/searchTool.vue';
import RoomForm from '@/components/room/RoomForm';

export default {
  name: 'Room',
  components: {
    RoomForm,
    searchTool,
  },
  data() {
    return {
      total: 0,
      page: 1,
      data: [],
      cacheData: [],
      columns,
      editingKey: '',
      boolLoading: false,
      formSearch: {
        selected: [],
        input: '',
      },
      statusList: [
        { text: '正常', value: ['on'] },
        { text: '关闭', value: ['off'] },
        { text: '不限', value: [] },
      ],
      windowHeight: window.innerHeight,
    };
  },
  created() {
    this.onFetchList();
  },
  methods: {
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        if (column === 'roomTitle') {
          target.title = value;
        } else {
          target[column] = value;
        }
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      this.onUpdateType(target);
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = '';
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    add() {
      this.$refs.typeForm.onReset();
      this.$refs.typeForm.visible = !this.$refs.typeForm.visible;
    },
    onSearch(formPass) {
      this.formSearch = formPass;
      this.page = 1;
      this.onFetchList();
    },
    onFetchList() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = TypeServices.postAdminFetchRoom(
        that.page,
        that.formSearch.input,
        token
      );
      request
        .then((res) => {
          that.total = res.data.data.total;
          that.data = res.data.data.data.map((row) => ({
            key: row.id,
            ...row,
          }));
          that.cacheData = that.data.map((item) => ({ ...item }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onAddType(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = TypeServices.postAdminInsertRoom(form, token);
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('新增失败');
            return;
          }
          that.onFetchList();
          that.add();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onUpdateType(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = TypeServices.postAdminUpdateRoom(form.id, form, token);
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
            that.onFetchList();
          }
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchList();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    status(record) {
      let that = this;
      var status = 'on';
      if (record.status == 'on') {
        status = 'off';
      }
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = TypeServices.postAdminUpdateStatusRoom(
        record.key,
        status,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          }
          that.onFetchList();
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchList();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.pagination {
  margin-top: 20px;
}
</style>
