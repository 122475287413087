import api from './api'
import utils from './utils'


const postAdminFetchStaff = (token, dateTime) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchStaffByDate, {
      dateTime
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminFetchRecordByDate = (token, dateTime) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchRecordByDate, {
      dateTime,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const postAdminInsertRecord = (form, token) => {
  var data = {
    startTime: new Date(form.startTime).getFullYear() + "-" + (new Date(form.startTime).getMonth() + 1) +
      "-" + new Date(form.startTime).getDate() + " " + new Date(form.startTime).getHours() +
      ":" + new Date(form.startTime).getMinutes() + ":" + new Date(form.startTime).getSeconds(),
    belongStaffId: form.belongStaffId,
    belongTypeId: form.belongTypeId,
    typeName: form.typeName,
    typeSalary: form.typeSalary,
    duration: form.duration,
    typeProfit: form.typeProfit,
    typePrice: form.typePrice,
    salary: form.salary,
    profit: form.profit,
    price: form.price,
    paymentWay: form.paymentWay,
    extraServices: form.extraServices,
    comment: form.comment,
    room_id: form.room_id,

  }

  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminInsertWorkRecord, data, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminFetchRecordById = (id, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchRecordById, {
      id
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminUpdateRecord = (form, token) => {
  var data = {
    id: form.id,
    startTime: new Date(form.startTime).getFullYear() + "-" + (new Date(form.startTime).getMonth() + 1) +
      "-" + new Date(form.startTime).getDate() + " " + new Date(form.startTime).getHours() +
      ":" + new Date(form.startTime).getMinutes() + ":" + new Date(form.startTime).getSeconds(),
    belongStaffId: form.belongStaffId,
    belongTypeId: form.belongTypeId,
    typeName: form.typeName,
    typeSalary: form.typeSalary,
    duration: form.duration,
    typeProfit: form.typeProfit,
    typePrice: form.typePrice,
    salary: form.salary,
    profit: form.profit,
    price: form.price,
    paymentWay: form.paymentWay,
    extraServices: form.extraServices,
    comment: form.comment,
    room_id: form.room_id,
  }

  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateWorkRecord, data, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminFetchRecordByDateAndPage = (token, start, end,belongStaffId, page) => {
  var data = {
    start,
    end,
    page
  }
  if(belongStaffId != null){
    data.belongStaffId = belongStaffId
  }
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchRecordByDateAndPage, data, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const postAdminFetchRecordAllByDate = (token, start, end,belongStaffId) => {
  var data = {
    start,
    end,
  }
  if(belongStaffId != null){
    data.belongStaffId = belongStaffId
  }
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchRecordAllByDate, data, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminUpdateStatusRecord = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateWorkRecordFull, {
      id,
      status
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}




const postAdminUpdateRecordList = (id, form, token) => {
  var data = {
    id: id,
    salary: form.salary,
    profit: form.profit,
    price: form.price,
    comment: form.comment,
    salaryPaid: form.salaryPaid,
  }

  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateWorkRecordFull, data, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateSalaryPaid = (id, salaryPaid, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateWorkRecordFull, {
      id,
      salaryPaid,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


export default {
  postAdminInsertRecord,
  postAdminFetchStaff,
  postAdminFetchRecordByDate,
  postAdminFetchRecordById,
  postAdminUpdateRecord,
  postAdminFetchRecordByDateAndPage,
  postAdminUpdateStatusRecord,
  postAdminUpdateRecordList,
  postAdminFetchRecordAllByDate,
  postAdminUpdateSalaryPaid,
}