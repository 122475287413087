import Vue from 'vue'
import VueRouter from 'vue-router'
import Shop from '../views/Shop.vue'
import Staff from '../views/Staff.vue'
import Record from '../views/Record.vue'
import Arrange from '../views/Arrange.vue'
import User from '../views/User.vue'
import Type from '../views/Type.vue'
import RecordList from '../views/RecordList.vue'
import Statistic from '../views/Statistic.vue'
import Operator from '@/views/Operator';
import Room from '@/views/Room';

// import VueCookies from 'vue-cookies';

Vue.use(VueRouter)
// Vue.use(VueCookies);

const routes = [{
    path: '/',
    name: 'Staff',
    component: Staff
  },
  {
    path: '/record',
    name: 'Record',
    component: Record
  },
  {
    path: '/recordList',
    name: 'RecordList',
    component: RecordList
  },
  {
    path: '/statistic',
    name: 'Statistic',
    component: Statistic
  },
  {
    path: '/type',
    name: 'Type',
    component: Type
  },
  {
    path: '/room',
    name: 'Room',
    component: Room
  },
  {
    path: '/arrange',
    name: 'Arrange',
    component: Arrange
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/operator',
    name: 'Operator',
    component: Operator
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to,form,next) => {
//   console.log(to)
//   console.log(form)
//   if(!VueCookies.get('fancyToken')){return}
//   next();
// })
export default router