import api from './api'
import utils from './utils'

const postAdminLogin = (username,password) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminLogin, {
      username,
      password,
    })
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminCheckUser = (token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCheckUser, {
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminFetchUser = (token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchUser, {
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const postAdminUpdateUser = (token,password) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateUser, {
      password
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const postAdminForgetUser = (username) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminForgotUser, {
      username
    })
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


export default {
  postAdminLogin,
  postAdminCheckUser,
  postAdminFetchUser,
  postAdminUpdateUser,
  postAdminForgetUser
}
