<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <div v-if="boolLogin">
        <a-layout
          id="components-layout-demo-custom-trigger"
          :style="{ 'min-height': windowHeight + 'px' }"
        >
          <a-layout-sider
            v-model="collapsed"
            :trigger="null"
            collapsible
          >
            <div
              v-if="user.logo.length > 0"
              class="logo"
              style="height: 50px"
            >
              <img
                class="logo-img"
                :src="user.logo[0].response.data"
              >
            </div>
            <a-menu
              v-model="$store.state.menuKeys"
              theme="dark"
              mode="inline"
            >
              <a-menu-item
                v-if="user.permission === 'admin'"
                key="Shop"
                @click="onNavTo('Shop')"
              >
                <a-icon type="crown" />
                <span>商家列表</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal'"
                key="Staff"
                @click="onNavTo('')"
              >
                <a-icon type="deployment-unit" />
                <span>员工列表</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal'"
                key="Type"
                @click="onNavTo('Type')"
              >
                <a-icon type="appstore" />
                <span>服务列表</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal'"
                key="Room"
                @click="onNavTo('Room')"
              >
                <a-icon type="apartment" />
                <span>房间列表</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal'"
                key="Arrange"
                @click="onNavTo('Arrange')"
              >
                <a-icon type="calendar" />
                <span>排班日历</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal' || user.permission === 'operator'"
                key="Record"
                @click="onNavTo('Record')"
              >
                <a-icon type="edit" />
                <span>上工记录</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal' || user.permission === 'operator' || user.permission === 'admin'"
                key="RecordList"
                @click="onNavTo('RecordList')"
              >
                <a-icon type="unordered-list" />
                <span>记录列表</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal'"
                key="Statistic"
                @click="onNavTo('Statistic')"
              >
                <a-icon type="edit" />
                <span>统计数据</span>
              </a-menu-item>
              <a-menu-item
                v-if="user.permission === 'normal'"
                key="Operator"
                @click="onNavTo('Operator')"
              >
                <a-icon type="user" />
                <span>操作员列表</span>
              </a-menu-item>
              <a-menu-item
                key="User"
                @click="onNavTo('User')"
              >
                <a-icon type="setting" />
                <span>账号设置</span>
              </a-menu-item>
              <a-menu-item
                key="logout"
                @click="onLogout"
              >
                <a-icon type="logout" />
                <span>退出登入</span>
              </a-menu-item>
              <a-icon
                class="trigger"
                style="position: absolute; right: 10px; bottom: 20px;"
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                @click="() => (collapsed = !collapsed)"
              />
            </a-menu>
          </a-layout-sider>
          <a-layout>
            <!--
            <a-layout-header style="background: #fff; padding: 0">
              <a-icon
                class="trigger"
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                @click="() => (collapsed = !collapsed)"
              />
            </a-layout-header>
            -->
            <a-layout-content
              :style="{
                margin: '24px 16px',
                padding: '24px',
                background: '#fff',
                minHeight: '280px',
              }"
            >
              <router-view />
            </a-layout-content>
          </a-layout>
        </a-layout>
      </div>
      <!-- 登入窗口 -->
      <div
        v-else
        class="login-block"
      >
        <a-card class="box">
          <a-form
            class="box-form"
            :form="form"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 17 }"
            @submit="handleSubmit"
          >
            <a-form-item label="用户名">
              <a-input
                v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your username!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="密码">
              <a-input
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ],
                  },
                ]"
                type="password"
              />
            </a-form-item>
            <div style="text-align: right; width: 100%; margin-bottom: 20px">
              <a
                class="login-form-forgot"
                @click="onForgotPassword"
              >
                Forgot password
              </a>
            </div>

            <a-form-item :wrapper-col="{ span: 25, offset: 0 }">
              <a-button
                class="box-btn"
                type="primary"
                html-type="submit"
              >
                登入
              </a-button>
            </a-form-item>
          </a-form>
        </a-card>
      </div>

      <!-- 忘记密码 -->
      <a-modal
        width="60%"
        title="商户操作"
        :mask-closable="false"
        :visible="visible"
        :confirm-loading="boolLoading"
        @ok="onSubmitPassword"
        @cancel="onCancelPassword"
      >
        <a-form-model
          ref="form"
          :model="formUser"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item
            label="用户名"
            required
          >
            <a-input v-model="formUser.username" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-config-provider>
  </div>
</template>

<script>
import HomeServices from './services/HomeServices';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
// import en_GB from "ant-design-vue/lib/locale-provider/en_GB";

export default {
  name: 'App',
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      boolLogin: false,
      boolAdmin: false,
      collapsed: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      user: {},
      formUser: {
        username: '',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      boolLoading: false,
      visible: false,

      locale: zhCN,
    };
  },
  computed: {},
  created() {
    this.onFetchUser();
    this.$store.state.menuKeys = [this.$route.name];
    document.title = 'Booking';
  },
  methods: {
    onNavTo(name) {
      if (this.$route.name == name) {
        return;
      }
      if (this.$route.name == 'Staff' && name == '') {
        return;
      }
      this.$router.push('/' + name);
    },
    onFetchUser() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      const request = HomeServices.postAdminCheckUser(token);
      return request
        .then((res) => {
          if (res.data.code == 200 || res.data.code == 201) {
            that.boolLogin = true;
            if (res.data.code == 201) {
              that.boolAdmin = true;
            }
          }
          that.user = res.data.data;
          localStorage.currentUser = JSON.stringify(res.data.data);
          if (that.user && that.user.name) {
            document.title = `${that.user.name} | Booking`;
          } else {
            document.title = 'Booking';
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit(e) {
      let that = this;
      e.preventDefault();
      that.form.validateFields((err, values) => {
        if (!err) {
          const request = HomeServices.postAdminLogin(
            values.username,
            that.$md5(values.password)
          );
          request
            .then((res) => {
              that.$cookies.set('fancyToken', res.data.data, '90d');
              that.onFetchUser().then(() => {
                if (that.boolLogin) {
                  if (that.user.permission === 'admin') {
                    this.onNavTo('Shop');
                    this.$store.state.menuKeys = ['Shop'];
                  } else if (that.user.permission === 'normal') {
                    //
                  } else if (that.user.permission === 'operator') {
                    this.onNavTo('Record');
                    this.$store.state.menuKeys = ['Record'];
                  }
                }
              });
              if (res.data.code != 200 && res.data.code != 201) {
                this.$message.error('登入失败');
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    onLogout() {
      this.$cookies.remove('fancyToken');
      // location.reload();
      // location.href = process.env.VUE_APP_WEB_ROOT;

      location.href = window.location.protocol + '//' + window.location.host;
    },

    onForgotPassword() {
      this.visible = !this.visible;
    },

    onSubmitPassword() {
      let that = this;
      that.boolLoading = true;
      const request = HomeServices.postAdminForgetUser(that.formUser.username);
      request
        .then((res) => {
          if (res.data.code == 200) {
            that.$message.success(
              '已将新密码发送至您的邮箱，如无法接收请联系我们。'
            );
            that.onCancelPassword();
          } else {
            that.$message.err('操作失败，请确认用户名是否正确');
          }
        })
        .catch(() => {
          that.$message.err('操作失败，请确认用户名是否正确');
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onCancelPassword() {
      this.visible = !this.visible;
      this.formUser = {
        username: '',
      };
    },
  },
};
</script>

<style scoped>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #722ed1;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.login-block {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-align: center;
}
.box {
  width: 600px;
  display: inline-block;
  margin-top: 200px;
}
.box-form {
  width: 100%;
}
.box-btn {
  width: 100%;
}
.logo-img {
  width: 100%;
  height: 100%;
}
</style>
