<template>
  <div>
    <a-modal
      width="60%"
      title="商户操作"
      :mask-closable="false"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          label="用户名"
          required
        >
          <a-input
            v-model="form.usernamePrefix"
            :disabled="boolEdit"
            :addon-after="'@' + form.usernameSuffix"
          />
          <a-button
            v-if="boolEdit"
            v-clipboard:copy="form.username"
            type="link"
          >
            复制
          </a-button>
        </a-form-model-item>
        <a-form-model-item
          v-if="!boolEdit"
          label="密码"
          required
        >
          <a-input
            v-model="form.password"
            type="password"
            autocomplete="new-password"
          />
        </a-form-model-item>

        <a-form-model-item label="LOGO(尺寸：170 x 50)">
          <a-upload
            :action="API_ROOT + '/api/v1/adminUploadFile'"
            list-type="picture-card"
            :file-list="form.logo"
            @change="handleChange"
          >
            <div v-if="form.logo.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
     
        <a-form-model-item
          label="名称"
          required
        >
          <a-input 
            v-model="form.namePrefix"
            :disabled="boolEdit"
            :addon-after="'@' + form.nameSuffix"
          />
        </a-form-model-item>
        <a-form-model-item
          label="电话"
          required
        >
          <a-input v-model="form.phone" />
        </a-form-model-item>
        <a-form-model-item
          label="地址"
          required
        >
          <a-input v-model="form.address" />
        </a-form-model-item>
        <a-form-model-item
          label="邮箱"
          required
        >
          <a-input v-model="form.email" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="form.comment" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'OperatorForm',
  props: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        username: '',
        usernamePrefix: '',
        usernameSuffix: '',
        password: '',
        name: '',
        namePrefix: '',
        nameSuffix: '',
        phone: '',
        address: '',
        email: '',
        logo: [],
        comment: '',
      },
      loading: false,
      visible: false,
      boolEdit: false,
      API_ROOT: process.env.VUE_APP_API_ROOT,
    };
  },
  methods: {
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const currentUser = JSON.parse(localStorage.currentUser);
          this.form.username = `${this.form.usernamePrefix}@${this.form.usernameSuffix}`;
          this.form.name = `${this.form.namePrefix}@${this.form.nameSuffix}`;
          this.form.company = currentUser.company;
          this.form.rent = currentUser.rent;
          this.form.paymentDate = currentUser.paymentDate;
          this.form.secret_key = null;

          console.log(this.form);
          if (this.boolEdit) {
            this.$emit('handleEditOk', this.form);
          } else {
            this.$emit('handleOk', this.form);
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = !this.visible;
      this.onReset();
    },
    onReset() {
      const currentUser = JSON.parse(localStorage.currentUser);
      this.form = {
        username: '',
        usernamePrefix: '',
        usernameSuffix: currentUser.name,
        password: '',
        name: '',
        namePrefix: '',
        nameSuffix: currentUser.name,
        phone: '',
        address: '',
        email: '',
        logo: [],
        comment: '',
      };
      this.boolEdit = false;
    },
    onSetShop(record) {
      const usernameSplit = record.username.split('@');
      const nameSplit = record.name.split('@');
      this.form = {
        id: record.id,
        username: record.username,
        usernamePrefix: usernameSplit[0],
        usernameSuffix: usernameSplit[1],
        password: record.password,
        name: record.name,
        namePrefix: nameSplit[0],
        nameSuffix: nameSplit[1],
        phone: record.phone,
        address: record.address,
        email: record.email,
        logo: record.logo,
        comment: record.comment,
      };
    },
    handleChange({ fileList }) {
      this.form.logo = fileList;
    },
  },
};
</script>

<style>
</style>