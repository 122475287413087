<template>
  <div>
    <search-tool
      excel-btn-text="导出Excel"
      date-start-picker-text="请选择记录起始日期"
      date-end-picker-text="请选择记录结束日期"
      :select-text="currentUser.permission === 'normal' || currentUser.permission === 'operator' ? '请选择职员' : '请选择商家'"
      :select-list="staffList"
      :excel-data="excelData"
      @add="add"
      @onSearch="onSearch"
    />
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="boolLoading"
      bordered
    >
      <template
        v-for="col in ['salary', 'profit', 'price', 'comment']"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record.key, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template
        slot="paymentWay"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <div v-if="text == 'cash'">
            现金
          </div>
          <div v-if="text == 'card'">
            银行卡
          </div>
          <div v-if="text == 'other'">
            其他
          </div>
        </div>
      </template>
      <template
        slot="status"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <a-tag
            v-if="text == 'on'"
            color="blue"
          >
            正常
          </a-tag>
          <a-tag
            v-else
            color="red"
          >
            关闭
          </a-tag>
        </div>
      </template>
      <template
        slot="salaryPaid"
        slot-scope="text, record"
      >
        <a-switch
          v-if="record.editable"
          :checked="text"
          checked-children="是"
          un-checked-children="否"
          @change="(e) => handleChange(e, record.key, 'salaryPaid')"
        />
        <div v-else>
          <a-tag
            v-if="text == true"
            color="blue"
          >
            是
          </a-tag>
          <a-tag
            v-else
            color="red"
          >
            否
          </a-tag>
        </div>
      </template>
      <template
        slot="operation"
        slot-scope="text, record"
      >
        <div
          v-if="currentUser.permission === 'normal' || (currentUser.permission === 'operator' && currentUser.id === record.created_by_user_id)"
          class="editable-row-operations"
        >
          <span v-if="record.editable">
            <a @click="() => save(record.key)">保存</a>
            <a-popconfirm
              title="Sure to cancel?"
              @confirm="() => cancel(record.key)"
            >
              <a>取消</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a
              :disabled="editingKey !== ''"
              @click="() => edit(record.key)"
            >编辑</a>
          </span>
          <span :hidden="editingKey !== ''">
            <a @click="() => toggleSalaryStatus(record, record.salaryPaid)">{{ record.salaryPaid ? "未结" : "结算" }}</a>
            <a-popconfirm
              v-if="currentUser.permission === 'normal'"
              title="确定删除?"
              @confirm="() => status(record, 'delete')"
            >
              <a>彻底删除</a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </a-table>

    <div>
      <div class="box-bottom-1">
        <a-pagination
          v-model="page"
          class="pagination"
          show-quick-jumper
          :default-current="1"
          :page-size="20"
          :total="total"
          @change="onFetchRecord"
        />
      </div>

      <div class="box-bottom-2">
        销售总额：{{ statistic.price }} 工资：{{ statistic.salary }} 利润：{{ statistic.profit }}
      </div>
    </div>
  </div>
</template>
<script>
import RecordServices from '../services/RecordServices';
import StaffServices from '../services/StaffServices';
import searchTool from '../components/searchTool.vue';
import moment from 'moment';
export default {
  components: {
    searchTool,
  },
  data() {
    const columns = [
      /*
      {
        title: 'ID',
        dataIndex: 'id',
        width: '60px',
        fixed: 'left',
      },
      */
      {
        title: '记录开始时间',
        dataIndex: 'startTime',
        width: '120px',
        fixed: 'left',
      },
      {
        title: '记录结束时间',
        dataIndex: 'endTime',
        width: '120px',
        fixed: 'left',
      },
      {
        title: '员工名称',
        dataIndex: 'staffName',
        scopedSlots: { customRender: 'staffName' },
        width: '90px',
        fixed: 'left',
      },
      {
        title: '操作员',
        dataIndex: 'created_by_user.username',
      },
      {
        title: '房间',
        dataIndex: 'room.title',
      },
      {
        title: '时长(分钟)',
        dataIndex: 'duration',
        scopedSlots: { customRender: 'duration' },
      },
      {
        title: '支付方式',
        dataIndex: 'paymentWay',
        scopedSlots: { customRender: 'paymentWay' },
      },

      {
        title: '总价',
        dataIndex: 'price',
        scopedSlots: { customRender: 'price' },
      },
      {
        title: '工资',
        dataIndex: 'salary',
        scopedSlots: { customRender: 'salary' },
      },
      {
        title: '利润',
        dataIndex: 'profit',
        scopedSlots: { customRender: 'profit' },
      },
      {
        title: '备注',
        dataIndex: 'comment',
        scopedSlots: { customRender: 'comment' },
      },
      {
        title: '工资已结',
        dataIndex: 'salaryPaid',
        scopedSlots: { customRender: 'salaryPaid' },
      },
    ];

    const currentUser = JSON.parse(localStorage.currentUser);
    if (currentUser.permission === 'normal' || currentUser.permission === 'operator') {
      columns.push(  {
        width: '170px',
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      });
    }

    if (currentUser.permission === 'admin') {
      columns.unshift(  {
        title: '商家',
        dataIndex: 'shop.username',
        width: '90px',
        fixed: 'left',
      });
    }

    return {
      total: 0,
      page: 1,
      data: [],
      cacheData: [],
      columns,
      editingKey: '',
      boolLoading: false,
      formSearch: {
        startDate: moment(),
        endDate: moment(),
        selected: null,
      },
      statusList: [
        { text: '正常', value: ['on'] },
        { text: '关闭', value: ['off'] },
        { text: '不限', value: [] },
      ],
      windowHeight: window.innerHeight,
      excelData: [],

      staffList: [],
      statistic:{
        price: 0,
        salary: 0,
        profit: 0,
      },
      currentUser: currentUser,
    };
  },
  created() {
    this.onFetchRecord();
    this.onFetchStaff();
  },
  methods: {
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      this.onUpdateRecord(target);
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = '';
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    add() {
      this.$refs.typeForm.onReset();
      this.$refs.typeForm.visible = !this.$refs.typeForm.visible;
    },
    onSearch(formPass) {
      this.formSearch = formPass;
      this.page = 1;
      this.onFetchRecord();
    },
    onFetchRecord() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminFetchRecordByDateAndPage(
        token,
        that.formSearch.startDate.format('YYYY-MM-DD'),
        that.formSearch.endDate.format('YYYY-MM-DD'),
        that.formSearch.selected,
        that.page
      );
      request
        .then((res) => {
          that.total = res.data.data.total;
          that.data = res.data.data.data.map((row) => ({
            key: row.id,
            ...row,
          }));
          that.cacheData = that.data.map((item) => ({ ...item }));
          that.onFetchAllRecord();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onUpdateRecord(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminUpdateRecordList(
        form.id,
        form,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
            that.onFetchRecord();
          }
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchRecord();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    status(record, status) {
      console.log(record);
      let that = this;

      if (status != 'delete') {
        if (record.status == 'on') {
          status = 'off';
        } else {
          status = 'on';
        }
      }

      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminUpdateStatusRecord(
        record.key,
        status,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          }
          that.onFetchRecord();
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchRecord();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    toggleSalaryStatus(record, salaryPaidStatus) {
      const that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminUpdateSalaryPaid(
        record.key,
        !salaryPaidStatus,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          }
          that.onFetchRecord();
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchRecord();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onFetchAllRecord() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminFetchRecordAllByDate(
        token,
        that.formSearch.startDate.format('YYYY-MM-DD'),
        that.formSearch.endDate.format('YYYY-MM-DD'),
        that.formSearch.selected
      );
      request
        .then((res) => {
          var price = 0;
          var salary = 0;
          var profit = 0;
          res.data.data.forEach((item) => {
            if (item.paymentWay == 'cash') {
              item.paymentWay = '现金';
            } else if (item.paymentWay == 'card') {
              item.paymentWay = '银行卡';
            } else {
              item.paymentWay = '其他';
            }
            price += parseFloat(item.price);
            salary += parseFloat(item.salary);
            profit += parseFloat(item.profit);
          });
          that.excelData = res.data.data.map((row) => {
            let exportData = {
              key: row.id,
              createTime: row.createTime,
              operator: row.created_by_user?.username,
              paymentWay: row.paymentWay,
              staffName: row.staffName,
              room: row.room?.title,
              price: row.price,
              salary: row.salary,
              profit: row.profit,
              comment: row.comment,
              salaryPaid: row.salaryPaid,
            };

            if (that.currentUser.permission === 'admin') {
              exportData.ownerUser = row.shop.username;
            }
            return exportData;
          });
          // 统计数据
          that.statistic = {
            price: price.toFixed(2),
            salary: salary.toFixed(2),
            profit: profit.toFixed(2),
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onFetchStaff() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = StaffServices.postAdminFetchAllStaff(null, token);
      request
        .then((res) => {
          that.staffList = res.data.data.map((row) => ({
            key: row.id,
            text: row.name,
            value: row.id,
          }));
          that.staffList.push({
            key: -1,
            text: '不限',
            value: null,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.pagination {
  margin-top: 20px;
}

.box-bottom-1{
  width: 30%;
  display: inline-block;
}

.box-bottom-2{
  width: 70%;
  display: inline-block;
  text-align: right;
}
</style>
