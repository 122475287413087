<template>
  <div>
    <search-tool
      :select-list="statusList"
      input-text="请输入公司名称"
      add-btn-text="新增商家"
      @add="add"
      @onSearch="onSearch"
    />
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="boolLoading"
      bordered
    >
      <template
        v-for="col in [
          'name',
          'company',
          'phone',
          'address',
          'email',
          'rent',
          'paymentDate',
          'comment',
        ]"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record.key, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template
        slot="logo"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <div>
            <image-tool
              v-for="(item, index) in text"
              :key="index"
              class="img-list"
              :img-url="item.response.data"
            />
          </div>
        </div>
      </template>
      <template
        slot="status"
        slot-scope="text"
      >
        <div class="editable-row-operations">
          <a-tag
            v-if="text == 'on'"
            color="blue"
          >
            正常
          </a-tag>
          <a-tag
            v-else
            color="red"
          >
            关闭
          </a-tag>
        </div>
      </template>
      <template
        slot="operation"
        slot-scope="text, record"
      >
        <div class="editable-row-operations">
          <!-- <span v-if="record.editable">
            <a @click="() => save(record.key)">保存</a>
            <a-popconfirm
              title="Sure to cancel?"
              @confirm="() => cancel(record.key)"
            >
              <a>取消</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a :disabled="editingKey !== ''" @click="() => edit(record.key)"
              >编辑</a
            >
          </span> -->
          <span>
            <a
              :disabled="editingKey !== ''"
              @click="onEdit(record)"
            >编辑</a>
          </span>
          <span>
            <a
              :disabled="editingKey !== ''"
              @click="openChangePasswordForm(record)"
            >密码</a>
          </span>
          <span>
            <a
              :disabled="editingKey !== ''"
              @click="() => status(record)"
            >{{
              record.status == "on" ? "关闭" : "恢复"
            }}</a>
          </span>
        </div>
      </template>
    </a-table>
    <a-pagination
      v-model="page"
      class="pagination"
      show-quick-jumper
      :default-current="1"
      :page-size="20"
      :total="total"
      @change="onFetchShop"
    />

    <shop-form
      ref="shopForm"
      @handleOk="onAddShop"
      @handleEditOk="onUpdateShop"
    />
    <shop-password-form 
      ref="shopPasswordForm"
      @handleOk="handleSetPassword"
    />
  </div>
</template>
<script>
const columns = [
  {
    title: 'username',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
  },
  {
    title: 'company',
    dataIndex: 'company',
    scopedSlots: { customRender: 'company' },
  },
  {
    title: 'logo(尺寸：170 x 50)',
    dataIndex: 'logo',
    scopedSlots: { customRender: 'logo' },
  },
  {
    title: 'name',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'phone',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: 'address',
    dataIndex: 'address',
    scopedSlots: { customRender: 'address' },
  },
  {
    title: 'email',
    dataIndex: 'email',
    scopedSlots: { customRender: 'email' },
  },
  {
    title: 'rent',
    dataIndex: 'rent',
    scopedSlots: { customRender: 'rent' },
  },
  {
    title: 'paymentDate',
    dataIndex: 'paymentDate',
    scopedSlots: { customRender: 'paymentDate' },
  },
  {
    title: 'comment',
    dataIndex: 'comment',
    scopedSlots: { customRender: 'comment' },
  },
  {
    title: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: '150px',
  },
];

import ShopServices from '../services/ShopServices';
import searchTool from '../components/searchTool.vue';
import shopForm from '../components/shop/shopForm.vue';
import imageTool from '../components/imageTool.vue';
import ShopPasswordForm from '@/components/shop/ShopPasswordForm';

export default {
  components: {
    ShopPasswordForm,
    searchTool,
    shopForm,
    imageTool
  },
  data() {
    return {
      total: 0,
      page: 1,
      data: [],
      cacheData: [],
      columns,
      editingKey: '',
      boolLoading: false,
      formSearch: {
        selected: [],
        input: '',
      },
      statusList: [
        { text: '正常', value: ['on'] },
        { text: '关闭', value: ['off'] },
        { text: '不限', value: [] },
      ],
      windowHeight: window.innerHeight,
    };
  },
  created() {
    this.onFetchShop();
  },
  methods: {
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      this.onUpdateShop(target);
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = '';
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    add() {
      this.$refs.shopForm.onReset();
      this.$refs.shopForm.visible = !this.$refs.shopForm.visible;
    },
    onSearch(formPass) {
      this.formSearch = formPass;
      this.page = 1;
      this.onFetchShop();
    },
    onFetchShop() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ShopServices.postAdminFetchShop(
        that.page,
        that.formSearch.input,
        that.formSearch.selected,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 201) {
            // 权限限制
            this.$router.push('/').catch(() => {});
          }
          that.total = res.data.data.total;
          that.data = res.data.data.data.map((row) => ({
            key: row.id,
            ...row,
          }));
          that.cacheData = that.data.map((item) => ({ ...item }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onAddShop(form) {
      if (form.paymentDate == '') {
        this.$message.error('新增失败，尚有未填信息');
        return;
      }

      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ShopServices.postAdminInsertShop(form, token);
      request
        .then((res) => {
          console.log(res);
          if (res.data.code == 500) {
            that.$message.error('新增失败' + res.data.errMsg.errorInfo[2]);
            return;
          }
          that.onFetchShop();
          that.add();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onUpdateShop(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ShopServices.postAdminUpdateShop(form.id, form, token);
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败' + res.data.errMsg);
            return;
          }
          that.onFetchShop();
          that.add();
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchShop();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    status(record) {
      let that = this;
      var status = 'on';
      if (record.status == 'on') {
        status = 'off';
      }
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ShopServices.postAdminUpdateStatusShop(
        record.key,
        status,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          }
          that.onFetchShop();
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchShop();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
    onEdit(record) {
      this.$refs.shopForm.onSetShop(record);
      this.$refs.shopForm.boolEdit = true;
      this.$refs.shopForm.visible = !this.$refs.shopForm.visible;
    },
    openChangePasswordForm(record) {
      this.$refs.shopPasswordForm.init(record);
    },
    handleSetPassword(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ShopServices.postAdminSetPassword(form.id, form.password, token);
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          }
          that.onFetchShop();
          // that.add();
        })
        .catch(() => {
          that.$message.error('更新失败');
          that.onFetchShop();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.pagination {
  margin-top: 20px;
}
</style>
