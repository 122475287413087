<template>
  <div class="search-tool">
    <div class="box-1">
      <a-select
        v-if="selectList.length > 0"
        :placeholder="selectText"
        style="margin-right: 10px; width: 120px"
        @change="handleChange"
      >
        <a-select-option v-for="(item, index) in selectList" :key="index">
          {{ item.text }}
        </a-select-option>
      </a-select>

      <div v-if="dateStartPickerText.length > 0" style="display:inline-block">
        <a-date-picker
          format="YYYY-MM-DD"
          :placeholder="dateStartPickerText"
          @change="onStartDateChange"
          :value="form.startDate"
        />
        <a-date-picker
          style="margin-left: 10px"
          format="YYYY-MM-DD"
          :placeholder="dateEndPickerText"
          @change="onEndDateChange"
          :value="form.endDate"
        />
        <a-button style="margin-left: 10px" @click="onSearch" type="primary"
          >搜索</a-button
        >
      </div>

      <a-input-search
        v-if="inputText.length > 0"
        :placeholder="inputText"
        style="width: 200px"
        @search="onSearch"
        enter-button
        v-model="form.input"
      />
    </div>
    <div class="box-2" v-if="addBtnText.length > 0">
      <a-button class="editable-add-btn" @click="add">{{
        addBtnText
      }}</a-button>
    </div>
    <div class="box-2" v-if="excelBtnText.length > 0">
      <download-excel :data="excelData">
        <a-button class="editable-add-btn">{{ excelBtnText }}</a-button>
      </download-excel>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    addBtnText: {
      type: String,
      default: "",
    },
    selectText: {
      type: String,
      default: "请选择",
    },
    inputText: {
      type: String,
      default: "",
    },
    dateStartPickerText: {
      type: String,
      default: "",
    },
    dateEndPickerText: {
      type: String,
      default: "",
    },
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
    excelBtnText: {
      type: String,
      default: "",
    },
    excelData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      form: {
        selected: null,
        selectedSec: [],
        selectedOrder: "",
        input: "",
        startDate: moment(),
        endDate: moment(),
      },
    };
  },
  methods: {
    add() {
      this.$emit("add");
    },
    onSearch() {
      this.$emit("onSearch", this.form);
    },
    handleChange(value) {
      this.form.selected = this.selectList[value].value;
    },
    onStartDateChange(e) {
      this.form.startDate = e;
    },
    onEndDateChange(e) {
      this.form.endDate = e;
    },
  },
};
</script>

<style>
.search-tool {
  width: 100%;
  display: inline-block;
}
.editable-add-btn {
}
.box-1 {
  width: 70%;
  display: inline-block;
  margin-bottom: 10px;
}
.box-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}
</style>