import api from './api'
import utils from './utils'

const postAdminFetchStaff = (page, name, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchStaffByPage, {
      page,
      name,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminFetchAllStaff = (name, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchAllStaff, {
      name,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const postAdminInsertStaff = (form, token) => {
  if (form.description.length == 0) {
    form.description = null
  }
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminInsertStaff, {
      name: form.name,
      nationality: form.nationality,
      nationality_zh: form.nationality_zh,
      age: form.age,
      imgUrls: form.imgUrls,
      description: form.description,
      description_zh: form.description_zh,
      nationalityImg: form.nationalityImg,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateStaff = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateStaff, {
      id,
      name: form.name,
      nationality: form.nationality,
      nationality_zh: form.nationality_zh,
      nationalityImg: form.nationalityImg,
      age: form.age,
      imgUrls: form.imgUrls,
      description: form.description,
      description_zh: form.description_zh,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateStatusStaff= (id,status,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateStaff, {
      id,
      status
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  postAdminFetchStaff,
  postAdminFetchAllStaff,
  postAdminInsertStaff,
  postAdminUpdateStaff,
  postAdminUpdateStatusStaff
}