<template>
  <div class="about">
    <a-form-model
      ref="form"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="用户名" required>
        <a-input v-model="form.username" disabled />
      </a-form-model-item>
      <a-form-model-item label="密码" required>
        <a-input v-model="form.password" type="password" autocomplete="new-password"/>
      </a-form-model-item>

      <a-form-model-item label="操作">
        <a-button type="primary" @click="onUpdate" block> 确认修改 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import HomeServices from "../services/HomeServices";

export default {
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 8 },
      form: {
        username: "",
        password: "",
      },
    };
  },
  created() {
    this.onFetchUser();
  },
  methods: {
    onFetchUser() {
      let that = this;
      var token = that.$cookies.get("fancyToken");
      that.boolLoading = true;
      const request = HomeServices.postAdminFetchUser(token);
      request
        .then((res) => {
          that.form = {
            username: res.data.data.username,
            password: "",
          };
        })
        .catch(() => {})
        .finally(() => {
          that.boolLoading = false;
        });
    },
    onUpdate() {
      let that = this;
      var token = that.$cookies.get("fancyToken");
      that.boolLoading = true;
      const request = HomeServices.postAdminUpdateUser(
        token,
        that.$md5(that.form.password)
      );
      request
        .then(() => {
          that.form.password = "";
          that.$message.success("修改成功");
        })
        .catch(() => {
          that.$message.error("修改失败");
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
  },
};
</script>

<style>
</style>
