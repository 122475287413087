<template>
  <div>
    <a-modal
      width="60%"
      title="新增房间"
      :maskClosable="false"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="名称" required>
          <a-input v-model="form.title" />
        </a-form-model-item>
        <a-form-model-item label="描述" required>
          <a-input v-model="form.description"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'RoomForm',
  props: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        title: "",
        description: "",
      },
      loading: false,
      visible: false,
    };
  },
  methods: {
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("handleOk", this.form);
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = !this.visible;
      this.onReset();
    },
    onReset() {
      this.form = {
        title: "",
        description: "",
      };
    },
  },
};
</script>

<style>
.time-1{
  width: 70%;
  display: inline-block;
}
.time-2{
  width: 20%;
  display: inline-block;
  margin-left: 10px;
}

</style>