<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["chartDat","options"],
  watch: {
    chartDat: {
      handler() {
        this.onSet();
      },
      deep: true,
    },
    options: {
      handler() {
        this.onSet();
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    onSet() {
      this.renderChart(this.chartDat,this.options);
    },
  },
};
</script>
//  {
//         labels: [
//           "January",
//           "February",
//           "March",
//           "April",
//           "May",
//           "June",
//           "July",
//           "August",
//           "September",
//           "October",
//           "November",
//           "December"
//         ],
//         datasets: [
//           {
//             label: "Data One",
//             backgroundColor: "#722ed1",
//             data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
//           }
//         ]
//       },