import axios from 'axios'
import api from './api'

export function request(action, params,token) {
  return new Promise((resolve, reject) => {
    axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url: api.ApiRootUrl + action,
        data: params
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default {
  postData(action, params,token) {
    return request(action, params,token)
  }
}