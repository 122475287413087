import api from './api'
import utils from './utils'

const postAdminFetchType = (page, name, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchTypeByPage, {
      page,
      name,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const postAdminInsertType = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminInsertType, {
      name: form.name,
      duration: form.duration,
      salary: form.salary,
      profit: form.profit,
      price: form.price,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateType = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateType, {
      id,
      name: form.name,
      duration: form.duration,
      salary: form.salary,
      profit: form.profit,
      price: form.price,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateStatusType = (id,status,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateType, {
      id,
      status
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminFetchAllType = (token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchAllType, {
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminFetchRoom = (page, name, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchRoomByPage, {
      page,
      name,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateRoom = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateRoom, {
      id,
      title: form.name,
      description: form.description,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminUpdateStatusRoom = (id,status,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateRoom, {
      id,
      status
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminInsertRoom = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminInsertRoom, {
      title: form.title,
      description: form.description,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const postAdminFetchAllRoom = (token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchAllRoom, {
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export default {
  postAdminFetchType,
  postAdminInsertType,
  postAdminUpdateType,
  postAdminUpdateStatusType,
  postAdminFetchAllType,
  postAdminFetchRoom,
  postAdminUpdateRoom,
  postAdminUpdateStatusRoom,
  postAdminInsertRoom,
  postAdminFetchAllRoom,
}