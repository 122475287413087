<template>
  <div>
    <a-modal
      width="60%"
      title="职员操作"
      :mask-closable="false"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          label="名称"
          required
        >
          <a-input v-model="form.name" />
        </a-form-model-item>

        <a-form-model-item
          label="国籍EN"
          required
        >
          <a-select
            show-search
            :filter-option="filterCountryOption"
            :value="selectedCountryCode"
            style="width: 200px"
            @change="onChangeNationality"
          >
            <a-select-option
              v-for="countryOption in countryOptions"
              :key="countryOption.code"
            >
              {{ countryOption.emoji }} {{ countryOption.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="国籍ZH"
            required
        >
          <a-input v-model="form.nationality_zh" />
        </a-form-model-item>

        <a-form-model-item label="照片">
          <a-upload
            :action="API_ROOT + '/api/v1/adminUploadFile'"
            list-type="picture-card"
            :file-list="form.imgUrls"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="form.imgUrls.length < 8">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item
          label="年龄"
          required
        >
          <a-input
            v-model="form.age"
            type="number"
          />
        </a-form-model-item>
        <a-form-model-item label="描述EN">
          <a-textarea
            v-model="form.description"
            :rows="5"
          />
        </a-form-model-item>
        <a-form-model-item label="描述ZH">
          <a-textarea
              v-model="form.description_zh"
              :rows="5"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handlePreviewCancel"
    >
      <img
        alt="example"
        style="width: 100%"
        :src="previewImage"
      >
    </a-modal>
  </div>
</template>

<script>
const emojiFlags = require('emoji-flags');

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  components: {},
  props: {},
  data() {
    const API_ROOT = process.env.VUE_APP_API_ROOT;
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        nationality: "",
        nationality_zh: "",
        nationalityImg: "",
        age: "",
        imgUrls: [],
        description: "",
        description_zh: "",
      },
      loading: false,
      visible: false,
      previewVisible: false,
      previewImage: "",
      boolEdit: false,
      flagList: [
        {
          key: `${API_ROOT}/file/flag.png`,
          value: `${API_ROOT}/file/flag.png`,
          title: "中国",
        },
        {
          key: `${API_ROOT}/file/flag2.png`,
          value: `${API_ROOT}/file/flag2.png`,
          title: "其他",
        },
      ],
      API_ROOT: API_ROOT,
      countryOptions: [],
      selectedCountryCode: null,
    };
  },
  created() {
    this.countryOptions = emojiFlags.data;
  },
  methods: {
    handleOk() {
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.boolEdit) {
            this.$emit("handleEditOk", this.form);
          } else {
            this.$emit("handleOk", this.form);
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = !this.visible;
      this.onReset();
    },
    onReset() {
      this.form = {
        name: "",
        nationality: "",
        nationality_zh: "",
        nationalityImg: "",
        age: "",
        imgUrls: [],
        description: "",
        description_zh: "",
      };
      this.boolEdit = false;
    },
    onSetStaff(record) {
      this.form = {
        id: record.id,
        name: record.name,
        nationality: record.nationality,
        nationality_zh: record.nationality_zh,
        nationalityImg: record.nationalityImg,
        age: record.age,
        imgUrls: record.imgUrls,
        description: record.description,
        description_zh: record.description_zh,
      };
      this.selectedCountryCode = this.countryOptions.find(item => item.name === record.nationality)?.code;
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.form.imgUrls = fileList;
    },
    handlePreviewCancel() {
      this.previewVisible = false;
    },
    onChangeNationalityImg(e) {
      // console.log(e)
      this.form.nationalityImg = e;
    },
    onChangeNationality(value) {
      const country = emojiFlags.countryCode(value);
      this.form.nationalityImg = country.emoji;
      this.form.nationality = country.name;
      this.selectedCountryCode = country.code;
    },
    filterCountryOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.flag-block{
  display: inline-block;
  margin-left:30px;
  vertical-align: top;
}
.flag-img {
  height: 30px;
  width: 30px;
}
</style>