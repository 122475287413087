const ApiRootUrl = `${process.env.VUE_APP_API_ROOT}/api/v1/`

export default {
  ApiRootUrl,

  // basic operation
  adminLogin: 'adminLogin',
  adminCheckUser: 'adminCheckUser',
  adminFetchUser: 'adminFetchUser',
  adminUpdateUser: 'adminUpdateUser',

  adminFetchShopByPage: 'adminFetchShopByPage',
  adminInsertShop: 'adminInsertShop',
  adminUpdateShop: 'adminUpdateShop',
  adminSetPassword: 'adminSetPassword',
  adminFetchStaffByPage: 'adminFetchStaffByPage',
  adminFetchAllStaff: 'adminFetchAllStaff',
  adminInsertStaff: 'adminInsertStaff',
  adminUpdateStaff: 'adminUpdateStaff',

  adminFetchTypeByPage: 'adminFetchTypeByPage',
  adminInsertType: 'adminInsertType',
  adminUpdateType: 'adminUpdateType',

  adminFetchArrange: 'adminFetchArrange',
  adminFetchArrangeById: 'adminFetchArrangeById',
  adminInsertArrange: 'adminInsertArrange',
  adminUpdateArrange: 'adminUpdateArrange',

  adminFetchStaffByDate: 'adminFetchStaffByDate',
  adminFetchRecordByDate: 'adminFetchRecordByDate',

  adminInsertWorkRecord: 'adminInsertWorkRecord',
  adminUpdateWorkRecord: 'adminUpdateWorkRecord',

  adminFetchAllType: "adminFetchAllType",
  adminFetchRecordById: "adminFetchRecordById",
  adminFetchRecordByDateAndPage: "adminFetchRecordByDateAndPage",
  adminUpdateWorkRecordFull: "adminUpdateWorkRecordFull",
  adminFetchRecordAllByDate: "adminFetchRecordAllByDate",

  adminForgotUser: "adminForgotUser",

  adminFetchOperatorByPage: 'adminFetchOperatorByPage',
  adminInsertOperator: 'adminInsertOperator',
  adminUpdateOperator: 'adminUpdateOperator',

  adminFetchRoomByPage: 'adminFetchRoomByPage',
  adminUpdateRoom: 'adminUpdateRoom',
  adminInsertRoom: 'adminInsertRoom',
  adminFetchAllRoom: 'adminFetchAllRoom',
}
