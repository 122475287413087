<template>
  <div class="container">
    <a-row :gutter="16">
      <a-col :span="3">
        <div
          ref="externalEvents"
          class="external-events"
        >
          <strong>员工</strong>
          <a-divider />

          <div :style="{height: staffDivHeight + 'px', overflow: 'auto'}">
            <div
              v-for="staff in staffList"
              :key="staff.id"
              class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event"
              :data-staff-id="staff.id"
            >
              <div class="fc-event-main">
                {{ staff.name }} {{ staff.nationalityImg }}
              </div>
            </div>
          </div>
        </div>
      </a-col>

      <a-col :span="21">
        <FullCalendar
          id="calendar"
          ref="fullCalendar"
          :options="calendarOptions"
        >
          <template v-slot:eventContent="arg">
            <b>{{ arg.timeText }}</b>
            <i>{{ arg.event.title }}</i>
          </template>
        </FullCalendar>
      </a-col>
    </a-row>

    <arrange-form
      ref="arrangeForm"
      @handleOk="onAddArrange"
      @handleEditOk="onUpdateArrange"
      @onStatus="onStatus"
    />
  </div>
</template>
<script>
import arrangeForm from '../components/arrange/arrangeForm.vue';
import ArrangeServices from '../services/ArrangeServices';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';
import moment from 'moment';
import StaffServices from '@/services/StaffServices';

export default {
  components: {
    arrangeForm,
    FullCalendar
  },

  data() {
    return {
      events: [],
      startTime: null,
      endTime: null,
      boolLoading: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        events: this.onFetchArrange,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
        droppable: true,
        drop: this.drop,
        height: window.innerHeight - 170,
      },
      staffList: [],
      staffDivHeight: window.innerHeight - 170,
    };
  },

  created() {
    this.onFetchStaff();
  },

  mounted() {
    const externalEvents = this.$refs.externalEvents;

    new Draggable(externalEvents, {
      itemSelector: '.fc-event',
      /*
      eventData: function(eventEl) {
        return {
          title: eventEl.innerText,
        };
      },
      */
    });

    let calendarApi = this.$refs.fullCalendar.getApi();
    setTimeout(function () {
      calendarApi.updateSize();
    }, 100);
  },

  methods: {
    onFetchStaff() {
      let that = this;
      const token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      StaffServices.postAdminFetchAllStaff(null, token)
        .then((res) => {
          that.staffList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
    
    onAddArrange(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ArrangeServices.postAdminInsertArrange(form, token);
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('新增失败');
            return;
          }
          let calendarApi = that.$refs.fullCalendar.getApi();
          calendarApi.refetchEvents();
          that.handleDateSelect();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onUpdateArrange(form) {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ArrangeServices.postAdminUpdateArrange(
        form.id,
        form,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
            this.$refs.arrangeForm.boolEdit = false;
            return;
          }
          let calendarApi = that.$refs.fullCalendar.getApi();
          calendarApi.refetchEvents();
          that.handleDateSelect();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onStatus(form) {
      let that = this;
      var status = 'off';

      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ArrangeServices.postAdminUpdateStatusArrange(
        form.id,
        status,
        token
      );
      request
        .then((res) => {
          if (res.data.code == 500) {
            that.$message.error('更新失败');
          } else {
            that.handleDateSelect();
            that.$refs.arrangeForm.boolEdit = false;
          }
          let calendarApi = that.$refs.fullCalendar.getApi();
          calendarApi.refetchEvents();
        })
        .catch(() => {
          that.$message.error('更新失败');
          let calendarApi = that.$refs.fullCalendar.getApi();
          calendarApi.refetchEvents();
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onFetchArrange(info) {
      let that = this;

      that.startTime = moment(info.start).format('YYYY-MM-DD');
      that.endTime = moment(info.end).format('YYYY-MM-DD');

      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = ArrangeServices.postAdminFetchArrange(
        that.startTime,
        that.endTime,
        token
      );
      return request
        .then((res) => {
          return res.data.data.map((row) => ({
            id: row.id,
            title: row.title,
            start: row.startTime,
            end: row.endTime,
            allDay: row.allDay,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    handleDateSelect(selectInfo) {
      this.$refs.arrangeForm.onReset(selectInfo ? moment(selectInfo.start) : undefined);
      this.$refs.arrangeForm.visible = !this.$refs.arrangeForm.visible;
    },

    handleEventClick(clickInfo) {
      this.$refs.arrangeForm.onPassForm(clickInfo.event.id);
      this.$refs.arrangeForm.boolEdit = true;
      this.handleDateSelect();
    },

    handleEvents(events) {
      this.events = events;
    },

    drop(value) {
      const date = moment(value.date);
      const staffId = parseInt(value.draggedEl.getAttribute('data-staff-id'));
      this.$refs.arrangeForm.onDropped(date, staffId);
    }
  },
};
</script>
<style>
.container {
}

.external-events .fc-event {
  cursor: move;
  padding: 12px;
  margin: 12px;
}

.fc-h-event, .fc-v-event {
  background-color: #722ed1;
  border-color: #722ed1;
}

</style>