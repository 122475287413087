<template>
  <div class="container">
    <a-spin :spinning="boolLoading">
      <div style="display: inline-block">
        <!-- <a-select
          v-if="staffList.length > 0"
          placeholder="请选择职员"
          style="margin-right: 10px; width: 120px"
          @change="onStaffChange"
        >
          <a-select-option v-for="(item, index) in staffList" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select> -->

        <a-range-picker
          v-model="form.dateRange"
          :ranges="{ '今日': [moment(), moment()], '最近7天': [moment().subtract(6, 'days'), moment()], '最近30天': [moment().subtract(30, 'days'), moment()] }"
          format="YYYY-MM-DD"
          :placeholder="['请选择起始日', '请选择结束日']"
          :allow-clear="false"
        />
        <a-button
          style="margin-left: 10px"
          type="primary"
          @click="onFetchRecord"
        >
          搜索
        </a-button>
      </div>
      <a-tabs
        :active-key="tabIndex"
        @change="onTabChange"
      >
        <a-tab-pane :key="0">
          <span slot="tab">
            <a-icon type="bar-chart" />
            销售额统计
          </span>
        </a-tab-pane>
        <a-tab-pane :key="1">
          <span slot="tab">
            <a-icon type="bar-chart" />
            单量统计
          </span>
        </a-tab-pane>
      </a-tabs>
      <div class="box-1">
        <BarChart
          ref="barChart"
          :style="{ height: windowHeight - 300 + 'px' }"
          :chart-dat="chartDat"
          :options="options"
        />
      </div>
      <!-- :style="{ height: windowHeight - 300 + 'px' }" -->

      <div class="box-2">
        <div class="rank-title">
          排行榜
        </div>

        <a-list
          item-layout="horizontal"
          :data-source="staffRank"
          class="box-2-list"
        >
          <a-list-item
            slot="renderItem"
            slot-scope="item, index"
          >
            <div>
              <a-badge
                v-if="index < 3"
                :count="index + 1"
                :number-style="firstRankStyle"
              />
              <a-badge
                v-else
                :count="index + 1"
                :number-style="lateRankStyle"
              />

              <div
                v-if="item.raw != undefined"
                class="staff-name"
              >
                {{ item.raw.staffName }} - {{ item.data }}
              </div>
            </div>
          </a-list-item>
        </a-list>
      </div>
    </a-spin>
  </div>
</template>

<script>
import BarChart from '../components/statistic/BarChart.vue';
import moment from 'moment';
import RecordServices from '../services/RecordServices';
import StaffServices from '../services/StaffServices';

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      labels: [],
      datasets: [],

      //  {
      //       label: "Data One",
      //       backgroundColor: "#722ed1",
      //       data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
      //     }

      staffRank: [
        {
          title: 'Ant Design Title 1',
        },
        {
          title: 'Ant Design Title 2',
        },
        {
          title: 'Ant Design Title 3',
        },
        {
          title: 'Ant Design Title 4',
        },
      ],

      lateRankStyle: {
        backgroundColor: '#fff',
        color: '#999',
        boxShadow: '0 0 0 1px #d9d9d9 inset',
        display: 'inline-block',
      },
      firstRankStyle: {
        backgroundColor: '#722ed1',
        color: '#fff',
        boxShadow: '0 0 0 1px #722ed1 inset',
        display: 'inline-block',
      },

      chartDat: {},
      options: {},

      form: {
        dateRange: [moment().subtract(6, 'days'), moment()]
      },
      tabIndex: 0,
      recordList: [],
      boolLoading: false,
      staffList: []
    };
  },
  mounted() {
    this.onFetchRecord();
    // this.onFetchStaff()
  },
  methods: {
    moment,
    onTabChange(e) {
      this.tabIndex = e;
      this.onFetchRecord();
    },

    onFetchRecord() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = RecordServices.postAdminFetchRecordAllByDate(
        token,
        that.form.dateRange[0].format('YYYY-MM-DD'),
        that.form.dateRange[1].format('YYYY-MM-DD')
      );
      request
        .then((res) => {
          that.recordList = res.data.data;
          that.onCalDataSet(that.recordList);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onCalDataSet(data) {
      var list = [];
      var dataList = [];
      var label = [];
      var staff = [];

      switch (this.tabIndex) {
      case 0:

        for (let d = this.form.dateRange[0].clone(); d.isSameOrBefore(this.form.dateRange[1], 'day'); d.add(1, 'days')) {
          list.push({
            label: d.format('YYYY-MM-DD'),
            data: {
              profit: 0.0,
              salary: 0.0,
            },
          });
        }

        data.forEach((item) => {
          var boolStaff = false;
          list.forEach((element) => {
            if (item.workDate === element.label) {
              element.data.profit += parseFloat(item.profit);
              element.data.salary += parseFloat(item.salary);
            }
          });
          staff.forEach((inside) => {
            if (inside.label === item.belongStaffId) {
              inside.data += parseFloat(item.price);
              boolStaff = true;
            }
          });

          if (!boolStaff) {
            staff.push({
              label: item.belongStaffId,
              data: parseFloat(item.price),
              raw: item,
            });
          }
        });

        label = list.map(item => item.label);

        this.chartDat = {
          labels: label,
          datasets: [
            {
              label: '利润',
              backgroundColor: '#722ed1',
              data: list.map(item => item.data.profit.toFixed(2)),
            },
            {
              label: '工资',
              backgroundColor: '#ee5b5b',
              data: list.map(item => item.data.salary.toFixed(2)),
            },
          ],
        };

        this.options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              stacked: true
            }]
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              // Use the footer callback to display the sum of the items showing in the tooltip
              footer: function(tooltipItems, data) {
                var sum = 0;

                tooltipItems.forEach(function (tooltipItem) {
                  sum += parseFloat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                });
                return '合计: ' + sum.toFixed(2);
              },
            },
            footerFontStyle: 'normal'
          },
        };

        staff.sort(function compare(a, b) {
          if (a.data < b.data) return 1;
          if (a.data > b.data) return -1;
          return 0;
        });
        staff.forEach(item => {
          item.data = item.data.toFixed(2);
        });

        break;

      case 1:

        for (let d = this.form.dateRange[0].clone(); d.isSameOrBefore(this.form.dateRange[1], 'day'); d.add(1, 'days')) {
          list.push({
            label: d.format('YYYY-MM-DD'),
            data: 0,
          });
        }

        data.forEach((item) => {
          var boolStaff = false;
          list.forEach((element) => {
            if (item.workDate === element.label) {
              element.data += 1;
            }
          });
          staff.forEach((inside) => {
            if (inside.label === item.belongStaffId) {
              inside.data += 1;
              boolStaff = true;
            }
          });

          if (!boolStaff) {
            staff.push({
              label: item.belongStaffId,
              data: 1,
              raw: item,
            });
          }
        });

        list.forEach((item) => {
          dataList.push(item.data);
          label.push(item.label);
        });

        this.chartDat = {
          labels: label,
          datasets: [
            {
              label: '单量',
              backgroundColor: '#722ed1',
              data: dataList,
            },
          ],
        };

        this.options = {responsive: true, maintainAspectRatio: false};

        staff.sort(function compare(a, b) {
          if (a.data < b.data) return 1;
          if (a.data > b.data) return -1;
          return 0;
        });

        break;
      }
      this.staffRank = staff;
      this.$refs.barChart.onSet();
    },

    onStaffChange() {
    },

    onFetchStaff() {
      let that = this;
      var token = that.$cookies.get('fancyToken');
      that.boolLoading = true;
      const request = StaffServices.postAdminFetchAllStaff(null, token);
      request
        .then((res) => {
          that.staffList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}

.box-1 {
  width: 70%;
  display: inline-block;
  vertical-align: top;
}

.box-2 {
  width: 25%;
  margin: 0% 2.5%;
  display: inline-block;
  vertical-align: top;
}

.chart {
  width: 100%;
  height: 200px;
}

.box-2-list {
  width: 100%;
  display: inline-block;
}

.rank-title {
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.staff-name {
  display: inline-block;
  margin-left: 10px;
}
</style>