<template>
  <div>
    <a-modal
      width="60%"
      title="商户操作"
      :mask-closable="false"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="rules"
      >
        <a-form-model-item
          label="用户名"
          required
        >
          <a-input
            v-model="form.username"
            :disabled="boolEdit"
          />
          <a-button
            v-if="boolEdit"
            v-clipboard:copy="form.username"
            type="link"
          >
            复制
          </a-button>
        </a-form-model-item>
        <a-form-model-item
          v-if="!boolEdit"
          label="密码"
          required
        >
          <a-input
            v-model="form.password"
            type="password"
          />
        </a-form-model-item>

        <a-form-model-item label="LOGO(尺寸：170 x 50)">
          <a-upload
            :action="API_ROOT + '/api/v1/adminUploadFile'"
            list-type="picture-card"
            :file-list="form.logo"
            @change="handleChange"
          >
            <div v-if="form.logo.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
     
        <a-form-model-item
          label="名称"
          required
        >
          <a-input 
            v-model="form.name"
            :disabled="boolEdit"
          />
        </a-form-model-item>
        <a-form-model-item
          label="公司名称"
          required
        >
          <a-input v-model="form.company" />
        </a-form-model-item>
        <a-form-model-item
          label="电话"
          required
        >
          <a-input v-model="form.phone" />
        </a-form-model-item>
        <a-form-model-item
          label="地址"
          required
        >
          <a-input v-model="form.address" />
        </a-form-model-item>
        <a-form-model-item
          label="邮箱"
          required
        >
          <a-input v-model="form.email" />
        </a-form-model-item>
        <a-form-model-item
          label="租金"
          required
        >
          <a-input
            v-model="form.rent"
            type="number"
          />
        </a-form-model-item>
        <a-form-model-item
          label="交付日期"
          required
        >
          <a-date-picker
            v-model="form.paymentDate"
            value-format="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="form.comment" />
        </a-form-model-item>

        <a-divider orientation="left">
          网站对接
        </a-divider>
        <a-form-model-item label="Secret Key">
          <a-input
            v-model="form.secret_key"
            :disabled="true"
          />
          <a-button
            v-clipboard:copy="form.secret_key"
            type="link"
          >
            复制
          </a-button>
          <a-button
            type="link"
            @click="regenerateSecretKey"
          >
            重新生成
          </a-button>
        </a-form-model-item>

        <a-divider orientation="left">
          Facebook 对接
        </a-divider>
        <a-form-model-item label="状态">
          <a-radio-group
            v-model="form.fb_status"
            name="fb_status"
            @change="onFbStatusChange"
          >
            <a-radio :value="'OFF'">
              关闭
            </a-radio>
            <a-radio :value="'ON'">
              开启
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="App ID"
          prop="fb_app_id"
        >
          <a-input v-model="form.fb_app_id" />
        </a-form-model-item>
        <a-form-model-item
          label="App Secret"
          prop="fb_app_secret"
        >
          <a-input v-model="form.fb_app_secret" />
        </a-form-model-item>
        <a-form-model-item
          label="Access Token"
          prop="fb_access_token"
        >
          <a-input v-model="form.fb_access_token" />
        </a-form-model-item>
        <a-form-model-item
          label="Page ID"
          prop="fb_page_id"
        >
          <a-input v-model="form.fb_page_id" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        username: '',
        password: '',
        name: '',
        company: '',
        phone: '',
        address: '',
        email: '',
        rent: '',
        paymentDate: '',
        logo: [],
        comment: '',
        secret_key: null,
      },
      loading: false,
      visible: false,
      boolEdit: false,
      API_ROOT: process.env.VUE_APP_API_ROOT,
      rules: {},
    };
  },
  methods: {
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.boolEdit) {
            this.$emit('handleEditOk', this.form);
          } else {
            this.$emit('handleOk', this.form);
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = !this.visible;
      this.onReset();
    },
    onReset() {
      this.form = {
        username: '',
        password: '',
        name: '',
        company: '',
        phone: '',
        address: '',
        email: '',
        rent: '',
        paymentDate: '',
        logo: [],
        comment: '',
        secret_key: null,
        fb_status: 'OFF',
        fb_app_id: null,
        fb_app_secret: null,
        fb_access_token: null,
        fb_page_id: null,
      };
      this.boolEdit = false;
    },
    onSetShop(record) {
      this.form = {
        id: record.id,
        username: record.username,
        password: record.password,
        name: record.name,
        company: record.company,
        phone: record.phone,
        address: record.address,
        email: record.email,
        rent: record.rent,
        paymentDate: record.paymentDate,
        logo: record.logo,
        comment: record.comment,
        secret_key: record.secret_key,
        fb_status: record.fb_status,
        fb_app_id: record.fb_app_id,
        fb_app_secret: record.fb_app_secret,
        fb_access_token: record.fb_access_token,
        fb_page_id: record.fb_page_id,
      };
    },
    handleChange({ fileList }) {
      this.form.logo = fileList;
    },
    regenerateSecretKey() {
      this.form.secret_key = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    onFbStatusChange(e) {
      const value = e.target.value;
      if (value === 'ON') {
        this.rules.fb_app_id = [{ required: true, message: '请输入密码', trigger: 'blur' },];
        this.rules.fb_app_secret = [{ required: true, message: '请输入密码', trigger: 'blur' },];
        this.rules.fb_access_token = [{ required: true, message: '请输入密码', trigger: 'blur' },];
        this.rules.fb_page_id = [{ required: true, message: '请输入密码', trigger: 'blur' },];
      } else if (value === 'OFF') {
        this.rules.fb_app_id = null;
        this.rules.fb_app_secret = null;
        this.rules.fb_access_token = null;
        this.rules.fb_page_id = null;
      }
    }
  },
};
</script>

<style>
</style>