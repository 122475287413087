<template>
  <div>
    <img
      v-if="imgUrl != null && imgUrl != undefined"
      class="row-img"
      :src="imgUrl"
      @click="onPreview"
    />
    <a-modal
      width="60%"
      :visible="show"
      @ok="show = false"
      @cancel="show = false"
    >
      <img
        v-if="imgUrl != null && imgUrl != undefined"
        class="row-img-preivew"
        :src="imgUrl"
      />
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "ImageTool",
  props: {
    imgUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    onPreview() {
      this.show = !this.show;
    },
  },
};
</script>

<style  scoped>
.row-img{
  width: 100px;
  height: 100px;
}
.row-img-preivew{
  width: 600px;
}
</style>
